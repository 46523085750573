import { ReactNode } from "react";
import Text from "./Text";
import { UseFormRegisterReturn } from "react-hook-form";
import clsx from "clsx";
import { Correct } from "./icons";
import { translator } from "../../utils";

type Props = {
  type?: string;
  width?: string | number;
  height?: string | number;
  label?: string;
  icon?: ReactNode;
  require?: boolean;
  form: UseFormRegisterReturn;
  error?: string;
  placeholder?: string;
};

function InputTextWithLabel({
  type = "text",
  width = 300,
  height,
  label,
  icon,
  require,
  form,
  error,
  placeholder,
}: Props) {
  return (
    <div className="flex flex-col gap-1 select-none" style={{ width: width }}>
      {(label || icon) && (
        <div className="flex items-center gap-1">
          <div className="flex items-center gap-2">
            {icon && icon}
            {label && <Text weight="medium">{label}</Text>}
          </div>
          {require && <div className="text-red-500">*</div>}
        </div>
      )}
      <input
        className={clsx(
          "border rounded-md py-2 px-3 font-noto-th w-full  focus:outline-none",
          {
            "border-black": !error,
            "border-red-600": !!error,
            "appearance-none checked:bg-[url('/public/icons/Correct.svg')] bg-no-repeat bg-center border-2 border-[#D9D9D9]":
              type === "checkbox",
          }
        )}
        style={{ height: height }}
        type={type}
        placeholder={placeholder}
        {...form}
      />
      <div className="h-[18px]">
        <Text color="red" size={12}>
          {translator(error ?? "")}
        </Text>
      </div>
    </div>
  );
}

export default InputTextWithLabel;
