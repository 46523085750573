import React from "react";

function Email() {
  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 1.625V1C1.33424 1 1.17527 1.06585 1.05806 1.18306C0.940848 1.30027 0.875 1.45924 0.875 1.625H1.5ZM16.5 1.625H17.125C17.125 1.45924 17.0592 1.30027 16.9419 1.18306C16.8247 1.06585 16.6658 1 16.5 1V1.625ZM1.5 2.25H16.5V1H1.5V2.25ZM15.875 1.625V11.625H17.125V1.625H15.875ZM14.8333 12.6667H3.16667V13.9167H14.8333V12.6667ZM2.125 11.625V1.625H0.875V11.625H2.125ZM3.16667 12.6667C2.59167 12.6667 2.125 12.2 2.125 11.625H0.875C0.875 12.2328 1.11644 12.8157 1.54621 13.2455C1.97598 13.6752 2.55888 13.9167 3.16667 13.9167V12.6667ZM15.875 11.625C15.875 12.2 15.4083 12.6667 14.8333 12.6667V13.9167C15.4411 13.9167 16.024 13.6752 16.4538 13.2455C16.8836 12.8157 17.125 12.2328 17.125 11.625H15.875Z"
        fill="black"
      />
      <path
        d="M1.5 1.625L9 9.125L16.5 1.625"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Email;
