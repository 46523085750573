import { Link } from "react-router-dom";
import Text from "../../../components/Text";
import Card from "../../../components/Card";
import { SaleProductType } from "../../types";
import { ShoppingCart } from "../../../components/icons";
import { Button } from "../../../components";
import ObliqueLineText from "./ObliqueLineText";
import { formatPrice } from "../../../../utils";

const CourseCard = ({
  id,
  name,
  courses,
  currentPrice,
  originalPrice,
  expireAt,
}: SaleProductType) => {
  let url = courses[0].profile.file.split("api")[1];

  return (
    <Link to={`/study/course/${id}`}>
      <Card className="flex flex-col rounded-md overflow-hidden">
        <img
          className="w-[280px] h-[200px] object-cover self-center rounded-md"
          src={`${process.env.REACT_APP_BACKEND}${url}`}
          alt="course-thumbnail"
        />
        <div className="flex flex-col p-3">
          {/* Category */}
          <Text size={13}>{`หมวดหมู่คอร์ส > ${courses[0].category.name}`}</Text>
          {/* Course Name */}
          <Text size={18} className="text-xl">
            {name}
          </Text>
          <div className="flex justify-between items-end">
            {/* Teacher */}
            {/* <div className="flex items-center gap-1">
              <div className="flex justify-center items-center w-7 h-7 aspect-square rounded-full bg-purple-400">
                P
              </div>
              <Text
                size={14}
              >{`${courses[0].teacher.firstName} ${courses[0].teacher.lastName}`}</Text>
            </div> */}
            <div></div>
            {/* Price */}
            <div className="flex flex-col items-end">
              {originalPrice ? (
                <ObliqueLineText>฿{formatPrice(originalPrice)}</ObliqueLineText>
              ) : null}
              <div className="text-grape text-2xl font-bold">
                ฿{formatPrice(currentPrice)}
              </div>
            </div>
          </div>
          <div className="flex justify-between mt-4">
            {/* Cart */}
            <div className="border border-grape p-1 rounded-md text-grape">
              <ShoppingCart stopColor="#6B51D1" />
            </div>
            {/* Buy Button */}
            <Button className="py-1 px-4">
              <Text color="white">ซื้อคอร์สนี้</Text>
            </Button>
          </div>
        </div>
      </Card>
    </Link>
  );
};

export default CourseCard;
