import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { store, persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import "./index.css";
import App from "./App";
import Example1 from "./pages/services/example_websites/web-1-new/Home";
import Example2 from "./pages/services/example_websites/web-2-geer/Home";
import Services from "./pages/services/Services";
import Eample from "./pages/services/example_websites/Eample";
import Study from "./pages/study/Study";
import Login from "./pages/study/Login";
import Register from "./pages/study/Register";
import Course from "./pages/study/Course";
import HomePage from "./pages/study/HomePage";
import Learning from "./pages/study/Learning";
import { CartOrder } from "./pages/study/Payment";
import MyCourse from "./pages/study/MyCourse";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Routes>
            <Route index element={<App />} />
            <Route path="services" element={<Services />} />
            <Route path="services/example" element={<Eample />} />
            <Route path="services/example/1" element={<Example1 />} />
            <Route path="services/example/2" element={<Example2 />} />
            <Route path="study" element={<Study />}>
              {/* <Route path="" element={<Courses />} /> */}
              <Route path="" element={<HomePage />} />
              <Route path="course/:id" element={<Course />} />
              <Route path="cart-order" element={<CartOrder />} />
            </Route>
            <Route path="study/course/:id/learning" element={<Learning />} />
            <Route path="study/login" element={<Login />} />
            <Route path="study/register" element={<Register />} />
            <Route path="study/mycourse" element={<MyCourse />} />
          </Routes>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
