import Video from "../../../components/icons/Video";
import Text from "../../../components/Text";
function NoCourseDisplay() {
  return (
    <div>
      <div className=" pt-16">
        <Video />
      </div>
      <div className=" pt-12 flex justify-center">
        <Text size={36} weight="semi-bold" color="#CBD5E1">
          คุณยังไม่มีคอร์สเรียน
        </Text>
      </div>
      <div className=" pt-16 flex justify-center">
        <Text
          className=" underline underline-offset-4"
          size={36}
          weight="semi-bold"
          color="#6B51D1"
        >
          เลือกซื้อคอร์สกันเถอะ !
        </Text>
      </div>
    </div>
  );
}
export default NoCourseDisplay;
