import React from 'react'

const RubbishBin = () => {
    return (
        <svg width="25" height="27" viewBox="0 0 25 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.9005 8.67975L20.9004 8.73234L20.8541 22.8156C20.8532 23.1029 20.7469 23.3781 20.5587 23.5807C20.3705 23.7832 20.1158 23.8965 19.8506 23.8956L5.85065 23.8496C5.58544 23.8487 5.33146 23.7338 5.14459 23.53C4.95772 23.3262 4.85327 23.0503 4.85422 22.763L4.9005 8.67975ZM6.89337 10.853L6.85777 21.6863L18.8577 21.7257L18.8933 10.8924L6.89337 10.853ZM9.88623 13.0295L11.8862 13.0361L11.8649 19.536L9.86487 19.5295L9.88623 13.0295ZM13.8862 13.0426L15.8862 13.0492L15.8648 19.5492L13.8649 19.5426L13.8862 13.0426ZM7.91117 5.43963L7.91829 3.27298C7.91923 2.98566 8.02549 2.71046 8.2137 2.50791C8.4019 2.30536 8.65663 2.19206 8.92184 2.19294L16.9218 2.21923C17.187 2.2201 17.441 2.33507 17.6279 2.53885C17.8147 2.74263 17.9192 3.01853 17.9182 3.30584L17.9111 5.4725L22.9111 5.48893L22.904 7.65558L2.90407 7.58985L2.91119 5.4232L7.91117 5.43963ZM9.91472 4.36288L9.91116 5.4462L15.9111 5.46592L15.9147 4.3826L9.91472 4.36288Z" fill="#94A3B8" />
        </svg>

    )
}

export default RubbishBin