import { useState, useEffect, ReactNode } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { removeCredential } from "../../../slice/user";
import { me } from "../../../apis";
import LogoWithName from "./LogoWithName";
import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";
import Text from "../../components/Text";
import { ButtonGradient, ButtonGradientOutlined } from "../../components";
import {
  ShoppingCart,
  Home,
  BookHeart,
  History,
  Logout,
} from "../../components/icons";
import { UserSliceType } from "../../../slice/user";

function Footer() {
  return (
    <div className="flex w-full py-8 bg-[#EAEAEA] justify-center">
      <div className="w-full max-w-[1200px]">
        <div className="grid grid-cols-4">
          <div className="col-span-1 flex flex-col gap-2">
            <LogoWithName />
            <Text size={20} weight="semi-medium" color="#000">
              แพลตฟอร์มรวมคอร์สออนไลน์
            </Text>
          </div>
          <div className="col-span-1">
            <Text size={20} weight="semi-medium" color="#000">
              คอร์สออนไลน์
            </Text>
          </div>
          <div className="col-span-1">
            <Text size={20} weight="semi-medium" color="#000">
              อื่นๆ
            </Text>
          </div>
          <div className="col-span-1 flex flex-col gap-2">
            <Text size={20} weight="semi-medium" color="#000">
              ติดต่อเรา
            </Text>
            <div className="flex gap-1"></div>
          </div>
        </div>
        <hr className="w-full text-[#6B51D1]" />
      </div>
    </div>
  );
}

export default Footer;
