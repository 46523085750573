import React, { PropsWithChildren } from "react";

type Props = {
  className?: string;
};

const Card = (props: PropsWithChildren<Props>) => {
  return (
    <div
      style={{ boxShadow: "1px 4px 4px 1px rgba(0,0,0,0.25)" }}
      className={`bg-white select-none ${props.className}`}
    >
      {props.children}
    </div>
  );
};

export default Card;
