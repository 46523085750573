import { ExerciseType, UserAnswerType } from "../../types";
import Text from "../../../components/Text";
import { ReactNode, useState, useEffect } from "react";
import { answerExercise, evaluateExercise } from "../../../../apis";

type Props = {
  exercises: ExerciseType[];
  chapterId: number;
  fetchExercises: (chapterId: number) => void;
};

function ExerciseArea({ exercises, chapterId, fetchExercises }: Props) {
  // const [currentExerciseIndex, setCurrentExerciseIndex] = useState(0);
  const [currentExercise, setCurrentExercise] = useState<ExerciseType>();
  const [userAnswer, setUserAnswer] = useState<UserAnswerType>();
  const [textAnswer, setTextAnswer] = useState("");

  const cc = (text: string): ReactNode => {
    const texts: any[] = [];
    while (text.length > 0) {
      if (text.includes("`")) {
        const start = text.indexOf("`");
        const end = text.indexOf("`", start + 1);
        texts.push(text.slice(0, start));
        texts.push(
          <span className="text-[16px] bg-slate-900 text-white px-1 border border-lime-400 rounded-md">
            {text.slice(start + 1, end)}
          </span>
        );
        text = text.slice(end + 1);
      } else {
        texts.push(text);
        text = "";
      }
    }
    return texts;
  };

  const handleAnswerClick = async (answer: string, exerciseId: number) => {
    if (userAnswer) return;
    try {
      const res = await answerExercise(exerciseId, answer);
      setUserAnswer(res);
    } catch (error: any) {}
  };

  const handleSelectExercise = (id: number) => {
    const exercise = exercises.find((exercise) => exercise.id === id);
    setCurrentExercise(exercise);
    setUserAnswer(exercise?.userAnswer);
  };

  const handleSendEvaluateAnswer = async () => {
    if (userAnswer) return;
    try {
      const res = await evaluateExercise(textAnswer);
      setUserAnswer(res);
    } catch (error: any) {}
  };

  const handleSendTextAnswer = async () => {
    handleAnswerClick(textAnswer, currentExercise!.id);
  };

  useEffect(() => {
    fetchExercises(chapterId);
  }, [userAnswer]);

  useEffect(() => {
    handleSelectExercise(exercises[0].id);
  }, []);

  return (
    <div className="flex flex-col gap-1 grow py-4 px-8">
      <Text size={30}>แบบทดสอบ</Text>
      <div className="flex justify-center w-full gap-2 my-3">
        {exercises.map((exercise, index) => (
          <div
            className={`w-12 h-12 rounded-full flex justify-center items-center cursor-pointer border-[#6B51D1] ${
              currentExercise?.id === exercise.id
                ? "bg-[#6B51D1]"
                : "bg-white border"
            }`}
            onClick={() => handleSelectExercise(exercise.id)}
          >
            <Text
              size={20}
              weight="medium"
              color={currentExercise?.id === exercise.id ? "white" : "#6B51D1"}
            >
              {index + 1}
            </Text>
          </div>
        ))}
      </div>
      <Text size={22} weight="medium">
        {currentExercise?.name}
      </Text>
      <Text size={16} weight="medium" className="flex gap-1 items-center">
        {cc(currentExercise?.description || "")}
      </Text>
      {currentExercise?.type === "choice" ? (
        <div className="flex flex-col gap-2 mt-2">
          {currentExercise?.answerChoices?.map((choice, index) => (
            <div
              className={`flex gap-2 items-center py-2 rounded-md px-3 ${
                !userAnswer ? "cursor-pointer" : "cursor-default"
              } ${
                !userAnswer
                  ? "bg-[#EEEAFF]"
                  : (choice === userAnswer.answer && userAnswer.correct) ||
                    choice === userAnswer.correctAnswer
                  ? "bg-[#D1FAE5]"
                  : choice === userAnswer.answer && !userAnswer.correct
                  ? "bg-[#ffc6c6]"
                  : "bg-[#EEEAFF]"
              }`}
              key={index}
              onClick={() => {
                handleAnswerClick(choice, currentExercise?.id);
              }}
            >
              <div className="w-6 h-6 rounded-full border border-slate-900 flex justify-center items-center">
                <Text size={16}>{String.fromCharCode(65 + index)}</Text>
              </div>
              <Text size={16}>{choice}</Text>
            </div>
          ))}
        </div>
      ) : currentExercise?.type === "code" ? (
        <div className="flex flex-col mt-2">
          <textarea
            className="w-full h-40 p-2 rounded-md border border-slate-900"
            placeholder="พิมพ์คำตอบของคุณที่นี่"
            value={textAnswer}
            onChange={(e) => setTextAnswer(e.target.value)}
          />
          {userAnswer ? null : (
            <button
              className="bg-[#6B51D1] text-white py-2 px-4 rounded-md mt-2"
              onClick={() => handleSendEvaluateAnswer()}
            >
              ส่งคำตอบ
            </button>
          )}
        </div>
      ) : (
        <div className="flex flex-col mt-2">
          <textarea
            className={`w-full h-40 p-2 rounded-md border border-slate-900 ${
              userAnswer ? "bg-[#b8b8b8]" : "bg-white"
            }`}
            placeholder="พิมพ์คำตอบของคุณที่นี่"
            value={userAnswer?.answer || textAnswer}
            onChange={(e) => setTextAnswer(e.target.value)}
            disabled={userAnswer ? true : false}
          />
          {userAnswer ? null : (
            <button
              className="bg-[#6B51D1] text-white py-2 px-4 rounded-md mt-2"
              onClick={() => handleSendTextAnswer()}
            >
              ส่งคำตอบ
            </button>
          )}
        </div>
      )}
    </div>
  );
}

export default ExerciseArea;
