import clsx from "clsx";
import React, { PropsWithChildren } from "react";
import { cn } from "../../utils";

type Props = {
  className?: string;
  props?: React.ButtonHTMLAttributes<HTMLButtonElement>;
};

const ButtonGradient = ({
  className,
  children,
  ...props
}: PropsWithChildren<Props>) => {
  return (
    <button
      className={cn(
        " bg-gradient-to-b d from-[#6B51D1] to-[#C33ED4] text-white rounded-[15px] text-[20px] leading-normal px-[21px] py-2 active:opacity-90 cursor-pointer hover:opacity-95 select-none",
        className
      )}
      {...props}
    >
      {children}
    </button>
  );
};

export default ButtonGradient;
