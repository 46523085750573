import React, { PropsWithChildren } from "react";
import { cn } from "../../utils";

type Props = {
  className?: string;
  props?: React.ButtonHTMLAttributes<HTMLButtonElement>;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  type?: "submit" | "button" | "reset";
};

const Button = ({
  className,
  children,
  onClick,
  type = "submit",
  ...props
}: PropsWithChildren<Props>) => {
  return (
    <button
      className={cn(
        "bg-[#6B51D1] text-white rounded text-[20px] leading-normal px-[21px] py-2 active:opacity-90 cursor-pointer hover:opacity-95 select-none ",
        className
      )}
      onClick={onClick}
      type={type}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
