import { Link } from "react-router-dom";
import { GoBack } from "../../components/icons";
import Text from "../../components/Text";

type Props = {
  label: string;
  to: string;
};

function GoBackButton({ label, to }: Props) {
  return (
    <Link to={to}>
      <div className="flex items-center gap-1 select-none">
        <div className="flex w-6">
          <GoBack />
        </div>
        <Text color="#6B51D1" weight="medium" size={20}>
          {label}
        </Text>
      </div>
    </Link>
  );
}

export default GoBackButton;
