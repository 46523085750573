// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.g {
  /* background: rgb(94, 0, 201); */
  /* color: linear-gradient(
    90deg,
    rgba(94, 0, 201, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  ); */
  background: -webkit-linear-gradient(
    0deg,
    rgb(111, 24, 211),
    rgba(255, 255, 255, 1)
  );
  -webkit-background-clip: text;
          background-clip: text;
  -webkit-text-fill-color: transparent;
}
`, "",{"version":3,"sources":["webpack://./src/pages/services/services.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;EACjC;;;;MAII;EACJ;;;;GAIC;EACD,6BAAqB;UAArB,qBAAqB;EACrB,oCAAoC;AACtC","sourcesContent":[".g {\n  /* background: rgb(94, 0, 201); */\n  /* color: linear-gradient(\n    90deg,\n    rgba(94, 0, 201, 1) 0%,\n    rgba(255, 255, 255, 1) 100%\n  ); */\n  background: -webkit-linear-gradient(\n    0deg,\n    rgb(111, 24, 211),\n    rgba(255, 255, 255, 1)\n  );\n  background-clip: text;\n  -webkit-text-fill-color: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
