import Text from "../../components/Text";

type Props = {
  firstName: string;
  lastName: string;
  textSize?: number;
};

function TextProfile({ firstName, lastName, textSize }: Props) {
  return (
    <div className="bg-gradient-to-b from-[#6B51D1] to-[#C33ED4] rounded-full aspect-square flex justify-center items-center w-12">
      <Text color="white" weight="medium" size={textSize ?? 22}>
        {Boolean(firstName[0]) && Boolean(lastName[0])
          ? firstName[0] + lastName[0]
          : "P"}
      </Text>
    </div>
  );
}

export default TextProfile;
