function ExerciseIcon({ size = 50 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.525 37.4993H43.75V8.33268C43.75 7.22761 43.311 6.16781 42.5296 5.3864C41.7482 4.605 40.6884 4.16602 39.5833 4.16602H12.5C9.9875 4.16602 6.25 5.8306 6.25 10.416V39.5827C6.25 44.1681 9.9875 45.8327 12.5 45.8327H43.75V41.666H12.525C11.5625 41.641 10.4167 41.2598 10.4167 39.5827C10.4167 37.9056 11.5625 37.5243 12.525 37.4993ZM16.6667 12.4993H35.4167V16.666H16.6667V12.4993Z"
        fill="#6B51D1"
      />
    </svg>
  );
}

export default ExerciseIcon;
