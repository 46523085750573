import { Navigate, Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCredential, setProfile } from "../../slice/user";
import * as y from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { login, me } from "../../apis";
import InputTextWithLabel from "../components/InputTextWithLabel";
import Background from "../components/Background";
import {
  Email,
  Key,
  PersonFill,
  KeyLock,
  ArrowLeftCurve,
  ShowPassword,
  HidePassword,
} from "../components/icons";
import Card from "../components/Card";
import Text from "../components/Text";
import { Button } from "../components";
import { LogoWithName, GoBackButton } from "./components";
import { useEffect, useState } from "react";
const LoginSchema = y.object({
  email: y.string().email().required(),
  password: y.string().required(),
});

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user);
  const [isShow, setIsShow] = useState(false);
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(LoginSchema),
  });

  const onSubmit = async (formData: any) => {
    const loginResponse = await login(formData.email, formData.password);
    dispatch(setCredential({ token: loginResponse.auth_token }));
    const meResponse = await me();

    dispatch(setProfile(meResponse));
  };

  if (user.id) {
    return <Navigate to={"/study"} />;
  }

  return (
    <>
      <div className=" absolute top-0 w-full min-h-screen overflow-hidden -z-50">
        <Background />
      </div>
      <form
        className="flex flex-col w-full min-h-screen mobile:w-[200px] justify-center items-center gap-7"
        onSubmit={handleSubmit(onSubmit)}
      >
        <LogoWithName />
        <Card className="w-[528px] p-[38px] flex flex-col items-center ">
          <div className="flex items-center gap-1">
            <div className="scale-90">
              <KeyLock />
            </div>
          </div>
          <InputTextWithLabel
            label="อีเมล"
            placeholder="อีเมล"
            icon={<Email />}
            require
            form={register("email")}
            error={errors?.email?.message}
            width={"100%"}
          />
          <div className="relative w-full">
            <InputTextWithLabel
              label="รหัสผ่าน"
              placeholder="รหัสผ่าน"
              icon={<Key />}
              require
              form={register("password")}
              error={errors?.password?.message}
              width={"100%"}
              type={isShow ? "text" : "password"}
            />
            <div
              className="absolute top-[calc(50%-12px)] right-3"
              onClick={() => setIsShow((prev) => !prev)}
            >
              {isShow ? (
                <div>
                  <ShowPassword />
                </div>
              ) : (
                <div>
                  <HidePassword />
                </div>
              )}
            </div>
          </div>
          <div className="w-full flex justify-end copy select-none">
            <Link to={"/study"}>
              <Text color="#6B51D1">ลืมรหัสผ่าน ?</Text>
            </Link>
          </div>
          <Button>เข้าสู่ระบบ</Button>

          <div className="flex gap-2 mt-9 select-none">
            <Text weight="medium" size={14}>
              ยังไม่มีบัญชี ?
            </Text>
            <Link to={"/study/register"}>
              <Text color="#6B51D1" size={14} weight="medium">
                ลงทะเบียน
              </Text>
            </Link>
          </div>
        </Card>
        <GoBackButton label="กลับหน้าหลัก" to="/study" />
      </form>
    </>
  );
}

export default Login;
