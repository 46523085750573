import { PropsWithChildren } from "react";

type Props = {};

function ObliqueLineText({ children }: PropsWithChildren<Props>) {
  return (
    <div className="relative">
      <div className="text-gray-500 px-2 text-[12px]">{children}</div>
      <div
        style={{
          position: "absolute",
          left: 0,
          top: "50%",
          right: 0,
          borderTop: "1px solid",
          borderColor: "gray",
          transform: "rotate(-10deg)",
        }}
      ></div>
    </div>
  );
}

export default ObliqueLineText;
