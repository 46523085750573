function ShoppingCart({
  stopColor = "#C33ED4",
}: {
  stopColor?: "#C33ED4" | "#6B51D1";
}) {
  return (
    <svg
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.8636 6.5H19.0341V5.98C19.0341 2.678 16.108 0 12.5 0C8.89205 0 5.96591 2.678 5.96591 5.98V6.5H1.13636C0.507812 6.5 0 6.96475 0 7.54V24.96C0 25.5352 0.507812 26 1.13636 26H23.8636C24.4922 26 25 25.5352 25 24.96V7.54C25 6.96475 24.4922 6.5 23.8636 6.5ZM8.52273 5.98C8.52273 3.96825 10.3018 2.34 12.5 2.34C14.6982 2.34 16.4773 3.96825 16.4773 5.98V6.5H8.52273V5.98ZM22.4432 23.66H2.55682V8.84H5.96591V11.7C5.96591 11.843 6.09375 11.96 6.25 11.96H8.23864C8.39489 11.96 8.52273 11.843 8.52273 11.7V8.84H16.4773V11.7C16.4773 11.843 16.6051 11.96 16.7614 11.96H18.75C18.9062 11.96 19.0341 11.843 19.0341 11.7V8.84H22.4432V23.66Z"
        fill="url(#paint0_linear_167_2203)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_167_2203"
          x1="12.5"
          y1="0"
          x2="12.5"
          y2="26"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6B51D1" />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default ShoppingCart;
