import React from "react";

const Video = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="345"
      height="291"
      viewBox="0 0 345 291"
      fill="none"
    >
      <path
        d="M344.344 277.688C344.344 281.193 342.951 284.556 340.472 287.035C337.993 289.514 334.631 290.906 331.125 290.906H13.875C10.3692 290.906 7.00693 289.514 4.52793 287.035C2.04894 284.556 0.65625 281.193 0.65625 277.688C0.65625 274.182 2.04894 270.819 4.52793 268.34C7.00693 265.861 10.3692 264.469 13.875 264.469H331.125C334.631 264.469 337.993 265.861 340.472 268.34C342.951 270.819 344.344 274.182 344.344 277.688ZM344.344 26.5312V211.594C344.344 218.605 341.558 225.33 336.6 230.288C331.642 235.246 324.918 238.031 317.906 238.031H27.0938C20.0821 238.031 13.3576 235.246 8.39961 230.288C3.44162 225.33 0.65625 218.605 0.65625 211.594V26.5312C0.65625 19.5196 3.44162 12.7951 8.39961 7.83711C13.3576 2.87912 20.0821 0.09375 27.0938 0.09375H317.906C324.918 0.09375 331.642 2.87912 336.6 7.83711C341.558 12.7951 344.344 19.5196 344.344 26.5312ZM231.984 119.062C231.984 116.838 231.421 114.649 230.35 112.7C229.278 110.75 227.732 109.102 225.854 107.909L153.151 61.6436C151.153 60.374 148.85 59.6644 146.484 59.5889C144.117 59.5135 141.774 60.075 139.699 61.2147C137.624 62.3544 135.893 64.0306 134.687 66.068C133.481 68.1054 132.844 70.4293 132.844 72.7969V165.328C132.844 167.696 133.481 170.02 134.687 172.057C135.893 174.094 137.624 175.771 139.699 176.91C141.774 178.05 144.117 178.612 146.484 178.536C148.85 178.461 151.153 177.751 153.151 176.481L225.854 130.216C227.732 129.023 229.278 127.375 230.35 125.425C231.421 123.476 231.984 121.287 231.984 119.062Z"
        fill="url(#paint0_linear_238_6457)"
        fillOpacity="0.5"
      />
      <defs>
        <linearGradient
          id="paint0_linear_238_6457"
          x1="172.5"
          y1="0.09375"
          x2="172.5"
          y2="290.906"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6B51D1" />
          <stop offset="1" stopColor="#C33ED4" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Video;
