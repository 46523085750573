import Text from "../../../components/Text";
import { Button } from "../../../components";
import { MyCourseType } from "../../types";
import { Link } from "react-router-dom";
import TextProfile from "../../components/TextProfile";
function MyCourseCard({ progress, course }: MyCourseType) {
  const urlProfileVideo = course.profile.file.split("api")[1];
  const urlProfileTeacher = course.teacher.profileImage?.file.split("api")[1];

  return (
    <div className="w-full flex flex-row gap-6 py-6 border-b border-[#CBD5E1]">
      <div className="w-[210px] aspect-video bg-slate-200 rounded-md overflow-hidden">
        <img
          src={`${process.env.REACT_APP_BACKEND}${urlProfileVideo}`}
          alt=""
        />
      </div>
      <div className="flex flex-col gap-3 w-[calc(100%-210px-24px)]">
        <Text size={20} weight="medium" color="#000">
          {course.name}
        </Text>
        <div className="flex flex-row justify-between pr-10">
          <div className="flex items-center gap-1">
            <div className="flex justify-center items-center w-7 h-7 aspect-square rounded-full overflow-hidden">
              {urlProfileTeacher ? (
                <img
                  src={`${process.env.REACT_APP_BACKEND}${urlProfileTeacher}`}
                  alt=""
                />
              ) : (
                <TextProfile
                  firstName={course.teacher.firstName}
                  lastName={course.teacher.lastName}
                  textSize={14}
                />
              )}
            </div>
            <Text size={14}>
              {course.teacher.firstName} {course.teacher.lastName}
            </Text>
          </div>
          <Button
            className={`py-1 w-[140px] h-[35px] ${
              progress === 0 ? null : "hidden"
            }`}
          >
            <Link to={`/study/course/${course.id}`}>
              <Text size={20} color="white" weight="semi-medium">
                เรียน
              </Text>
            </Link>
          </Button>
          <Button
            className={`py-1 w-[140px] h-[35px] bg-[#C33ED4] ${
              progress > 0 && progress < 95 ? null : "hidden"
            }`}
          >
            <Link to={`/study/course/${course.id}`}>
              <Text size={20} color="white" weight="semi-medium">
                เรียนต่อ
              </Text>
            </Link>
          </Button>
          <Button
            className={`py-1 w-[140px] h-[35px] ${
              progress >= 95 ? null : "hidden"
            }`}
          >
            <Link to={`/study/course/${course.id}`}>
              <Text size={20} color="white" weight="semi-medium">
                เรียนอีกครั้ง
              </Text>
            </Link>
          </Button>
        </div>
        <Text size={16} color="#94A3B8" weight="semi-medium">
          ความคืบหน้า
          {progress >= 95 ? " เรียนจบหลักสูตรแล้ว" : " " + progress + "%"}
        </Text>
      </div>
    </div>
  );
}
export default MyCourseCard;
