import { useState, useEffect, ReactNode } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { removeCredential } from "../../../slice/user";
import { me } from "../../../apis";
import LogoWithName from "./LogoWithName";
import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";
import Text from "../../components/Text";
import { ButtonGradient, ButtonGradientOutlined } from "../../components";
import { Home, BookHeart, History, Logout } from "../../components/icons";
import { UserSliceType } from "../../../slice/user";
import IconCart from "./icons/IconCart";
import IconNotification from "./icons/IconNotification";
import TextProfile from "./TextProfile";

function Nav() {
  const dispatch = useDispatch();
  const user: UserSliceType = useSelector((state: any) => state.user);
  const location = useLocation();

  const NavItem = ({ url, text }: { url: string; text: string }) => {
    const isCurrentPath = location.pathname === url;
    const color = isCurrentPath ? "#6B51D1" : "#000000";

    return (
      <Link to={url}>
        <div className="flex items-center" style={{ color: color }}>
          <Text weight="medium" color={color}>
            {text}
          </Text>
          {isCurrentPath ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </div>
      </Link>
    );
  };

  const NavProfile = ({ user }: { user: UserSliceType }) => {
    const [profile, setProfile] = useState<UserSliceType>();
    const [isProfileMenuExpand, setIsProfileMenuExpand] =
      useState<boolean>(false);

    const getProfile = async () => {
      const res = await me();

      setProfile(res);
    };

    useEffect(() => {
      getProfile();
    }, []);

    if (!profile) return <></>;

    const ProfileMenu = ({
      icon,
      text,
      onClick,
    }: {
      icon: ReactNode;
      text: string;
      onClick: () => void;
    }) => {
      return (
        <div
          className="flex items-center cursor-pointer group hover:bg-[#6b51d125] w-full px-4 py-2"
          onClick={onClick}
        >
          {icon}
          <Text
            color=""
            weight="medium"
            className="whitespace-nowrap group-hover:text-[#6B51D1]"
          >
            {text}
          </Text>
        </div>
      );
    };

    return (
      <div className="flex items-center gap-4">
        <div className="cursor-pointer">
          <IconNotification />
        </div>
        <Link className="cursor-pointer" to={"/study/cart"}>
          <IconCart />
        </Link>
        <div className="flex flex-col relative items-end">
          <div
            className="flex items-center gap-2 cursor-pointer group"
            onClick={() => setIsProfileMenuExpand(!isProfileMenuExpand)}
          >
            <TextProfile
              firstName={profile?.firstName}
              lastName={profile?.lastName}
            />
            <Text
              weight="medium"
              color=""
              className="group-hover:text-[#6B51D1]"
            >
              {`${profile?.firstName} ${profile?.lastName}`}
            </Text>
          </div>
          <div
            className="h-0 relative"
            style={{ display: isProfileMenuExpand ? "block" : "none" }}
          >
            <div className="mt-2 absolute right-0 bg-white z-30">
              <div className="flex flex-col items-start border border-gray-300 shadow-lg">
                <Link className="w-full" to="/study">
                  <ProfileMenu
                    icon={<Home />}
                    text="หน้าหลัก"
                    onClick={() => {}}
                  />
                </Link>
                <Link to="/study/mycourse">
                  <ProfileMenu
                    icon={<BookHeart />}
                    text="คอร์สเรียนของฉัน"
                    onClick={() => {}}
                  />
                </Link>
                <ProfileMenu
                  icon={<History />}
                  text="ประวัติการสั่งซื้อ"
                  onClick={() => {}}
                />
                <div className="w-full px-2">
                  <div className="border border-gray-300"></div>
                </div>
                <ProfileMenu
                  icon={<Logout />}
                  text="ออกจากระบบ"
                  onClick={() => dispatch(removeCredential())}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const navItems = [
    {
      text: "คอร์สออนไลน์",
      url: "/study",
    },
    {
      text: "ติดต่อเรา",
      url: "#",
    },
    {
      text: "ทดลองเรียนฟรี",
      url: "#",
    },
  ];

  return (
    <div className="flex w-full max-w-[1295px]  items-center justify-between px-4">
      <div className="flex items-center gap-4">
        <LogoWithName />
        {navItems.map(({ url, text }) => (
          <NavItem url={url} text={text} />
        ))}
      </div>
      {user.id ? (
        <NavProfile user={user} />
      ) : (
        <div className="flex items-center gap-2">
          <Link to={"/study/login"}>
            <ButtonGradientOutlined text="เข้าสู่ระบบ" />
          </Link>
          <Link to={"/study/register"}>
            <ButtonGradient className="text-[16px] px-4 py-1 rounded-xl">
              <Text color="white">ลงทะเบียน</Text>
            </ButtonGradient>
          </Link>
        </div>
      )}
    </div>
  );
}

export default Nav;
