function Home() {
  return (
    <svg
      width="26"
      height="25"
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_238_6324)">
        <path
          d="M12.8965 4.94527C12.8238 4.87573 12.7271 4.83691 12.6265 4.83691C12.5259 4.83691 12.4291 4.87573 12.3564 4.94527L3.36719 13.5327C3.32901 13.5692 3.29864 13.6131 3.27791 13.6617C3.25718 13.7103 3.24652 13.7626 3.24658 13.8154L3.24512 21.875C3.24512 22.2894 3.40974 22.6868 3.70276 22.9798C3.99579 23.2728 4.39322 23.4375 4.80762 23.4375H9.5C9.7072 23.4375 9.90591 23.3551 10.0524 23.2086C10.1989 23.0621 10.2813 22.8634 10.2813 22.6562V16.0156C10.2813 15.912 10.3224 15.8126 10.3957 15.7394C10.4689 15.6661 10.5683 15.625 10.6719 15.625H14.5781C14.6817 15.625 14.7811 15.6661 14.8543 15.7394C14.9276 15.8126 14.9688 15.912 14.9688 16.0156V22.6562C14.9688 22.8634 15.0511 23.0621 15.1976 23.2086C15.3441 23.3551 15.5428 23.4375 15.75 23.4375H20.4404C20.8548 23.4375 21.2523 23.2728 21.5453 22.9798C21.8383 22.6868 22.0029 22.2894 22.0029 21.875V13.8154C22.003 13.7626 21.9923 13.7103 21.9716 13.6617C21.9509 13.6131 21.9205 13.5692 21.8823 13.5327L12.8965 4.94527Z"
          fill="url(#paint0_linear_238_6324)"
        />
        <path
          d="M24.0952 11.9214L20.4429 8.42725V3.125C20.4429 2.9178 20.3606 2.71909 20.2141 2.57257C20.0676 2.42606 19.8688 2.34375 19.6616 2.34375H17.3179C17.1107 2.34375 16.912 2.42606 16.7655 2.57257C16.619 2.71909 16.5366 2.9178 16.5366 3.125V4.6875L13.7085 1.9834C13.4439 1.71582 13.0503 1.5625 12.625 1.5625C12.2012 1.5625 11.8086 1.71582 11.544 1.98389L1.15822 11.9204C0.854511 12.2134 0.816425 12.6953 1.09279 13.0127C1.16219 13.0928 1.24717 13.158 1.34255 13.2042C1.43793 13.2504 1.54172 13.2768 1.6476 13.2816C1.75348 13.2864 1.85924 13.2697 1.95844 13.2324C2.05764 13.195 2.14821 13.1379 2.22463 13.0645L12.3565 3.38281C12.4292 3.31327 12.5259 3.27446 12.6265 3.27446C12.7271 3.27446 12.8238 3.31327 12.8965 3.38281L23.0293 13.0645C23.1786 13.2076 23.3785 13.2857 23.5853 13.2817C23.792 13.2777 23.9888 13.1918 24.1323 13.043C24.4321 12.7324 24.4072 12.2197 24.0952 11.9214Z"
          fill="url(#paint1_linear_238_6324)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_238_6324"
          x1="12.624"
          y1="4.83691"
          x2="12.624"
          y2="23.4375"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6B51D1" />
          <stop offset="1" stopColor="#C33ED4" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_238_6324"
          x1="12.625"
          y1="1.5625"
          x2="12.625"
          y2="13.2824"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6B51D1" />
          <stop offset="1" stopColor="#C33ED4" />
        </linearGradient>
        <clipPath id="clip0_238_6324">
          <rect
            width="25"
            height="25"
            fill="white"
            transform="translate(0.125)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Home;
