import { createSlice } from "@reduxjs/toolkit";

export type UserSliceType = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  token: string;
};

export const userSlice = createSlice({
  name: "user",
  initialState: {
    id: null,
    email: null,
    firstName: null,
    lastName: null,
    token: null,
  },
  reducers: {
    setCredential: (state, { payload: { token } }) => {
      state.token = token;
    },
    setProfile: (state, { payload: { id, email, firstName, lastName } }) => {
      state.id = id;
      state.email = email;
      state.firstName = firstName;
      state.lastName = lastName;
    },
    removeCredential: (state) => {
      state.id = null;
      state.email = null;
      state.firstName = null;
      state.lastName = null;
      state.token = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCredential, removeCredential, setProfile } =
  userSlice.actions as any;

export default userSlice.reducer;
