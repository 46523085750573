function PlayVideo() {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.0003 42.8337C33.5066 42.8337 42.8337 33.5066 42.8337 22.0003C42.8337 10.4941 33.5066 1.16699 22.0003 1.16699C10.4941 1.16699 1.16699 10.4941 1.16699 22.0003C1.16699 25.3337 1.95033 28.4837 3.33991 31.2774C3.71074 32.0191 3.83366 32.867 3.61908 33.6691L2.37949 38.3066C2.25718 38.7654 2.25764 39.2483 2.38083 39.7068C2.50401 40.1654 2.74559 40.5835 3.08135 40.9193C3.4171 41.2551 3.83523 41.4966 4.2938 41.6198C4.75237 41.743 5.23527 41.7435 5.69408 41.6212L10.3316 40.3816C11.1366 40.179 11.9879 40.2775 12.7253 40.6587C15.6064 42.0933 18.7818 42.8379 22.0003 42.8337Z"
        stroke="#6B51D1"
        strokeWidth="1.5"
      />
      <path
        opacity="0.5"
        d="M30.3333 22.0004C30.3333 20.2399 28.4021 19.0545 24.5417 16.6816C20.625 14.2754 18.6667 13.0733 17.2083 13.9566C15.75 14.8399 15.75 17.2274 15.75 22.0004C15.75 26.7733 15.75 29.1608 17.2083 30.0441C18.6687 30.9274 20.625 29.7254 24.5417 27.3191C28.4 24.9462 30.3333 23.7587 30.3333 22.0004Z"
        stroke="#6B51D1"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default PlayVideo;
