import React, { useEffect } from "react";
import { AccountCircle } from "@mui/icons-material";
import { Link, useSearchParams } from "react-router-dom";
import {
  Navigation,
  Pagination,
  EffectCoverflow,
  Autoplay,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
// import { SwiperModule } from "swiper/types";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "./swiper.css";
import "./services.css";
import Slider from "./components/Slider";
import Logo from "../../images/logo-white.png";
import SoftwareImage from "../../images/software.png";
import AgentsImage from "../../images/agents.png";
import LineImage from "../../images/line_rich.png";

// const MenuStyled = styled.div`
//   background-color: rgb(231, 223, 0);
//   color: black;
//   padding: 3px;
//   text-align: center;
// `;

let slides = [
  {
    url: "https://images.unsplash.com/photo-1494500764479-0c8f2919a3d8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
  },
  {
    url: "https://images.unsplash.com/photo-1506744038136-46273834b3fb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
  },
  {
    url: "https://images.unsplash.com/photo-1434725039720-aaad6dd32dfe?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1642&q=80",
  },
];

export default function Services() {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!searchParams.get("ln")) {
      setSearchParams({ ln: "th" });
    }
  }, []);

  const servicesIntro = [
    {
      header: "Software Development",
      detail: `บริการพัฒนาเว็บไซต์ทุกรูปแบบ ทั้ง ระบบจอง, คลังสินค้า, โฆษณา, QR-Code และร้านอาหาร
      ด้วยเครื่องมือที่ทันสมัยมีความรวดเร็ว
      สามารถพัฒนาได้อย่างรวดเร็วด้วยทีมงานมืออาชีพ
      ที่มีประสบการณ์กับงานใหญ่ๆมาแล้วมากมาย`,
    },
    {
      header: "Outsource",
      detail: `บริการ outsource สำหรับผู้ประกอบการที่ไม่ต้องการจ้างพนักงานประจำ
      ที่อาจจะไม่มีประสบการณ์ และมีค่าใช้จ่ายมากกว่าในระยะยาว
      เรามีทั้งบริการสัญญาระยะสั้น 2 เดือน จนถึง ระยะยาวไม่เกินครั้งละ
      1 ปี`,
    },
    {
      header: "Line OA",
      detail: `บริการพัฒนาระบบ Line official เพื่อธุรกิจ ไม่ว่าจะเป็น
      สำหรับการลงทะเบียน, จัดการสินค้า, แจ้งข้อมูลทางสถิติ และอีกมากมาย`,
    },
  ];

  const Menu = ({ text, bg }: { text: string; bg: string }) => {
    console.log(bg);
    const x = `bg-[${bg}]`;
    return <div className={`text-black p-2 text-center ${x}`}>{text}</div>;
  };

  const servicesDetail = [
    {
      img: SoftwareImage,
      detail: () => (
        <div className=" flex flex-col items-start">
          <p className="text-4xl">
            บริการทำ <span className="g font-extrabold">Web Site</span>{" "}
            ทุกรูปแบบ
          </p>
          <p className="text-gray-400">
            ขายออนไลน์, คลังสินค้า, ส่งของ, โรงแรม, ร้านอาหาร และอื่นๆ
          </p>
          <div className="flex">
            {/* Menu */}
            <div className="flex flex-col">
              <Menu text="รายละเอียด" bg="#E7DF00"></Menu>
              <Menu text="ขั้นตอนการปฎิบัติงาน" bg="#0cf0a8"></Menu>
              <Menu text="บริการหลังการขาย" bg="#e700a6"></Menu>
            </div>
            {/* Detail (Swap) */}
            <div></div>
          </div>
          {/* <p className="mt-2 text-xl">ขั้นตอนการปฎิบัติงาน</p>
          <div className="w-full grid grid-cols-1 ">
            <li>เก็บข้อมูล รายละเอียดงาน</li>
            <li>ประเมินระยะเวลา และเสนอราคา</li>
            <li>ทำข้อตกลง</li>
            <li>เริ่มปฎิบัติงาน</li>
            <li>อัพเดตความคืบหน้าอย่างน้อย 1 ครั้ง/สัปดาห์</li>
            <li>ส่งงานให้ผู้ว่าจ้างตรวจสอบ</li>
            <li>แก้ไขบัคต่างๆ</li>
            <li>ส่งมอบงาน</li>
          </div> */}
        </div>
      ),
    },
    {
      img: AgentsImage,
      detail: () => (
        <div className=" flex flex-col items-start">
          <p className="text-2xl">บริการทำ Web Site ทุกรูปแบบ</p>
          <p className="text-gray-400">
            ขายออนไลน์, คลังสินค้า, ส่งของ, โรงแรม, ร้านอาหาร และอื่นๆ
          </p>
          <p className="mt-2 text-xl">เครื่องมือที่ใช้</p>
          <div className="w-full grid grid-cols-2 ">
            <li>ReactJS</li>
            <li>NextJS</li>
            <li>TailwindCSS</li>
            <li>NodeJS</li>
            <li>Django</li>
            <li>MongoDB</li>
            <li>PostgreSQL</li>
            <li>MySQL</li>
          </div>
        </div>
      ),
    },
    {
      img: LineImage,
      detail: () => (
        <div className=" flex flex-col items-start">
          <p className="text-2xl">บริการทำ Web Site ทุกรูปแบบ</p>
          <p className="text-gray-400">
            ขายออนไลน์, คลังสินค้า, ส่งของ, โรงแรม, ร้านอาหาร และอื่นๆ
          </p>
          <p className="mt-2 text-xl">เครื่องมือที่ใช้</p>
          <div className="w-full grid grid-cols-2 ">
            <li>ReactJS</li>
            <li>NextJS</li>
            <li>TailwindCSS</li>
            <li>NodeJS</li>
            <li>Django</li>
            <li>MongoDB</li>
            <li>PostgreSQL</li>
            <li>MySQL</li>
          </div>
        </div>
      ),
    },
  ];

  const ServiceDetail = ({
    img,
    detail,
    index,
  }: {
    img: string;
    detail: any;
    index: number;
  }) => {
    return (
      <div
        className={`w-full flex ${
          index % 2 === 0 ? "flex-row" : "flex-row-reverse"
        } justify-center items-center`}
      >
        <div className="w-[600px] flex justify-center items-center">
          <img className="w-[300px]" src={img} alt="" />
        </div>
        <div
          className={`w-[600px] flex ${
            index % 2 === 0 ? "justify-start" : "justify-end"
          }`}
        >
          {detail()}
        </div>
      </div>
    );
  };

  const SwiperItem = ({ img }: { img: string }) => {
    return (
      <SwiperSlide>
        <div className="w-full h-full group flex justify-center items-center">
          <img src={img} alt="" />
          <div className=" w-26 h-12 px-4 absolute hidden text-white bg-slate-900/50 border rounded-3xl group-hover:flex justify-center items-center">
            ดูตัวอย่าง
          </div>
        </div>
      </SwiperSlide>
    );
  };

  const swiperItems = [
    AgentsImage,
    SoftwareImage,
    LineImage,
    AgentsImage,
    SoftwareImage,
    LineImage,
    AgentsImage,
    SoftwareImage,
    LineImage,
  ];

  return (
    <div className="w-full min-h-fit bg-slate-900 flex flex-col">
      {/* Header */}
      <header className="w-full h-20 flex justify-between items-center px-10 text-white border-b border-slate-100/20">
        {/* Left */}
        <div className="w-40 h-10 object-contain">
          <img className="w-10" src={Logo} alt="logo" />
        </div>
        {/* Middle */}
        <div className="flex gap-10 text-xl">
          <div>Home</div>
          <Link to={"example"}>
            <div>Example</div>
          </Link>
          <div>About Us</div>
          <div>Contact</div>
        </div>
        {/* Right */}
        <div className="text-5xl flex justify-end items-center gap-6 w-40">
          <p
            className=" cursor-pointer text-xl"
            onClick={() =>
              searchParams.get("ln") === "th"
                ? setSearchParams({ ln: "en" })
                : setSearchParams({ ln: "th" })
            }
          >
            {searchParams.get("ln") === "th" ? "English" : "ภาษาไทย"}
          </p>
          <AccountCircle fontSize="inherit" />
        </div>
      </header>
      {/* body */}
      <main className="w-[1200px] mx-auto flex flex-col justify-start items-center text-white gap-20">
        {/* Promotion */}
        <Slider slides={slides} width={1000} height={600} />
        {/* Services */}
        <div className="flex flex-col items-center">
          <div className="text-4xl">บริการของเรา</div>
          <div className=" flex mt-10">
            {servicesIntro.map((service, i) => (
              <>
                {i > 0 ? (
                  <div className="w-[1px] min-h-fit border border-white/60"></div>
                ) : (
                  <></>
                )}
                <div className=" flex flex-col w-[400px]">
                  <div className="text-center text-2xl font-semibold">
                    {service.header}
                  </div>
                  <div className="p-4 flex text-center">{service.detail}</div>
                </div>
              </>
            ))}
          </div>
        </div>
        {/* Services Detail */}
        {/* {servicesDetail.map((serviceDetail, i) => (
          <ServiceDetail
            img={serviceDetail.img}
            detail={serviceDetail.detail}
            index={i}
          />
        ))} */}
        {/* Example */}
        <div className="w-full h-80 text-black flex flex-col items-center gap-10">
          <div className="text-4xl text-white">ตัวอย่างเว็บไซต์</div>
          {/* <Swiper
            // install Swiper modules
            modules={
              [
                Navigation,
                EffectCoverflow,
                Pagination,
                Autoplay,
              ] as SwiperModule[]
            }
            // onAutoplayTimeLeft={onAutoplayTimeLeft}
            autoplay={{
              pauseOnMouseEnter: true,
              delay: 2000,
              disableOnInteraction: false,
            }}
            grabCursor={true}
            // spaceBetween={50}
            slidesPerView={3}
            centeredSlides={true}
            navigation
            // loop={true}
            effect={"coverflow"}
            coverflowEffect={{
              rotate: 50,
              stretch: 0,
              depth: 200,
              modifier: 1,
              slideShadows: true,
            }}
            pagination={true}
            // scrollbar={{ draggable: true }}
            // onSwiper={(swiper) => console.log(swiper)}
            // onSlideChange={() => console.log("slide change")}
          >
            {swiperItems.map((o, i) => SwiperItem({ img: o }))}
          </Swiper> */}
        </div>
      </main>
      {/* footer */}
      <footer className="w-full h-20 flex justify-start items-center px-10 mt-20 border-t border-white/60 text-white">
        <div>
          Copyright © 2023 Private Session Co.,Ltd. All rights reserved.
        </div>
      </footer>
    </div>
  );
}
