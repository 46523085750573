import React from 'react'

const KeyLock = () => {
    return (
        <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.4997 24.7916C18.2732 24.7916 19.0151 24.4843 19.5621 23.9373C20.109 23.3903 20.4163 22.6485 20.4163 21.8749C20.4163 21.1014 20.109 20.3595 19.5621 19.8125C19.0151 19.2655 18.2732 18.9583 17.4997 18.9583C16.7261 18.9583 15.9843 19.2655 15.4373 19.8125C14.8903 20.3595 14.583 21.1014 14.583 21.8749C14.583 22.6485 14.8903 23.3903 15.4373 23.9373C15.9843 24.4843 16.7261 24.7916 17.4997 24.7916ZM26.2497 11.6666C27.0232 11.6666 27.7651 11.9739 28.3121 12.5209C28.8591 13.0678 29.1663 13.8097 29.1663 14.5833V29.1666C29.1663 29.9401 28.8591 30.682 28.3121 31.229C27.7651 31.776 27.0232 32.0833 26.2497 32.0833H8.74967C7.97613 32.0833 7.23426 31.776 6.68728 31.229C6.1403 30.682 5.83301 29.9401 5.83301 29.1666V14.5833C5.83301 13.8097 6.1403 13.0678 6.68728 12.5209C7.23426 11.9739 7.97613 11.6666 8.74967 11.6666H10.208V8.74992C10.208 6.81605 10.9762 4.96138 12.3437 3.59393C13.7111 2.22648 15.5658 1.45825 17.4997 1.45825C18.4572 1.45825 19.4054 1.64686 20.2901 2.0133C21.1747 2.37974 21.9786 2.91684 22.6557 3.59393C23.3328 4.27103 23.8699 5.07485 24.2363 5.95952C24.6027 6.84418 24.7913 7.79236 24.7913 8.74992V11.6666H26.2497ZM17.4997 4.37492C16.3394 4.37492 15.2266 4.83586 14.4061 5.65633C13.5856 6.4768 13.1247 7.5896 13.1247 8.74992V11.6666H21.8747V8.74992C21.8747 7.5896 21.4137 6.4768 20.5933 5.65633C19.7728 4.83586 18.66 4.37492 17.4997 4.37492Z" fill="#6B51D1" />
        </svg>

    )
}

export default KeyLock