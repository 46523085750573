import Text from "../../../components/Text";
import ExerciseIcon from "../../../components/icons/ExerciseIcon";
import { ExerciseType } from "../../types";

type Props = {
  onClick: (index: number) => void;
  chapterId: number;
  exercises: ExerciseType[];
};

function Exercise({ onClick, chapterId, exercises }: Props) {
  return (
    <div
      className="flex items-center gap-2 cursor-pointer bg-white px-3 py-3 w-[300px]"
      onClick={() => onClick(chapterId)}
    >
      <div className="flex justify-center w-8">
        <ExerciseIcon />
      </div>
      <div className="flex flex-col">
        <Text weight="medium">แบบทดสอบ</Text>
        <div className="text-sm pl-2">{exercises.length} ข้อ</div>
      </div>
    </div>
  );
}

export default Exercise;
