import React, { useState, useEffect } from "react";
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  FiberManualRecord,
} from "@mui/icons-material";

/**
 *
 * @param {Array<{url}>} slides
 */

function Slider({
  slides,
  width,
  height,
}: {
  slides: any[];
  width: number;
  height: number;
}) {
  const [currentCarousel, setCurrentCarousel] = useState(0);
  const [holdCarousel, setHoldCarousel] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!holdCarousel)
        setCurrentCarousel((prevCurrentCarousel) => {
          const isLastSlide = prevCurrentCarousel === slides.length - 1;
          const newIndex = isLastSlide ? 0 : prevCurrentCarousel + 1;
          return newIndex;
        });
    }, 1500);
    return () => clearInterval(interval);
  }, [holdCarousel]);

  const prevSlide = () => {
    const isFirstSlide = currentCarousel === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentCarousel - 1;
    setCurrentCarousel(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentCarousel === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentCarousel + 1;
    setCurrentCarousel(newIndex);
  };

  const goToSlide = (index: number) => {
    setCurrentCarousel(index);
  };

  return (
    <div
      style={{ maxWidth: width, height: height }}
      className=" w-full m-auto py-10 px-2 relative "
    >
      <div
        style={{ backgroundImage: `url(${slides[currentCarousel].url})` }}
        className="w-full h-full rounded-2xl bg-center duration-500 group bg-contain bg-no-repeat"
        onMouseEnter={() => setHoldCarousel(true)}
        onMouseLeave={() => setHoldCarousel(false)}
      >
        {/* Left Arrow */}
        <div className="hidden group-hover:flex items-center absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
          <KeyboardArrowLeft onClick={prevSlide} fontSize="large" />
        </div>
        {/* Right Arrow */}
        <div className="hidden group-hover:flex absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
          <KeyboardArrowRight onClick={nextSlide} fontSize="large" />
        </div>
      </div>
      {/* Dot */}
      <div className="flex top-4 justify-center py-2">
        {slides.map((slide: any, index: number) => (
          <div key={index} className=" text-sm cursor-pointer text-white">
            <FiberManualRecord
              fontSize="small"
              onClick={() => goToSlide(index)}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Slider;
