function IconNotification() {
  return (
    <svg
      width="26"
      height="29"
      viewBox="0 0 26 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.1022 22.1923V23.1538C17.1022 24.1739 16.6782 25.1522 15.9235 25.8735C15.1687 26.5948 14.1451 27 13.0777 27C12.0103 27 10.9866 26.5948 10.2319 25.8735C9.47713 25.1522 9.05312 24.1739 9.05312 23.1538V22.1923M23.8736 20.235C22.2587 18.3462 21.1186 17.3846 21.1186 12.1773C21.1186 7.40865 18.5706 5.70974 16.4734 4.88462C16.1948 4.77524 15.9326 4.52404 15.8477 4.2506C15.4798 3.05409 14.4485 2 13.0777 2C11.7068 2 10.6749 3.05469 10.3108 4.2518C10.2259 4.52825 9.96367 4.77524 9.6851 4.88462C7.58541 5.71094 5.03988 7.40385 5.03988 12.1773C5.03673 17.3846 3.89665 18.3462 2.28179 20.235C1.61271 21.0174 2.19879 22.1923 3.36905 22.1923H22.7926C23.9566 22.1923 24.5389 21.0138 23.8736 20.235Z"
        stroke="url(#paint0_linear_2902_1050)"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2902_1050"
          x1="13.0769"
          y1="2"
          x2="13.0769"
          y2="27"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6B51D1" />
          <stop offset="1" stop-color="#C33ED4" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default IconNotification;
