import React from "react";
import Text from "../../../components/Text";
import { RubbishBin } from "../../../components/icons";
import ObliqueLineText from "../../SaleProducts/components/ObliqueLineText";
const CardOrder = () => {
  return (
    <div className="flex gap-5 w-full px-[10px] py-4 h-[147px] border-b border-[#CBD5E1]">
      <div className="bg-slate-500 w-[211px] h-[115px]"></div>
      <div className="flex flex-col flex-1 justify-between">
        <div className="w-full flex ">
          <Text weight="medium" size={20}>
            ชื่อคอร์ส
          </Text>
        </div>
        <div className="w-full flex justify-end">
          <RubbishBin />
        </div>
        <div className="w-full flex items-center ga-4">
          <Text color="#6B51D1" size={20} weight="medium">
            ฿1,500
          </Text>
          <div className="relative flex justify-center w-[84px]">
            <Text size={16} weight="semi-medium" color="#94A3B8">
              2,500
            </Text>
            <div className="w-[80%] h-px rotate-[-16deg] absolute top-[calc(50%)] bg-[#94A3B8]"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardOrder;
