import axios, { AxiosResponse } from "axios";
import { store } from "./store";
import { removeCredential } from "./slice/user";

type ListResponse = {
  count: number;
  next: string | null;
  previous: string | null;
  results: any[];
};

const BACKEND = process.env.REACT_APP_BACKEND;

const getHeaders = () => {
  const state = store.getState();
  const token = state.user.token;

  const headers: any = {};
  if (token) headers.Authorization = `Token ${token}`;
  return headers;
};

export const get = async (url: string) => {
  try {
    const res = await axios.get(BACKEND + url, {
      headers: getHeaders(),
    });
    return res;
  } catch (error: any) {
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      store.dispatch(removeCredential());
      const res = await axios.get(BACKEND + url);
      return res;
    } else {
      throw error;
    }
  }
};

export const post = async (url: string, payload: any) => {
  const res = await axios.post(BACKEND + url, payload, {
    headers: getHeaders(),
  });
  return res;
};

export const login = async (email: string, password: string) => {
  const res = await axios.post(BACKEND + `/auth/token/login/`, {
    email,
    password,
  });
  return res.data;
};

export const register = async (
  phone: string,
  firstName: string,
  lastName: string,
  email: string,
  password: string
): Promise<AxiosResponse> => {
  return axios.post(BACKEND + `/auth/users/`, {
    phone,
    firstName,
    lastName,
    email,
    password,
  });
};

export const me = async () => {
  try {
    const res = await get(`/auth/users/me/`);
    return res.data;
  } catch (error) {
    // Handle /me error
    if (axios.isAxiosError(error)) store.dispatch(removeCredential());
    else store.dispatch(removeCredential());
  }
};

export const getCourses = async (option: any = null) => {
  const res = await get(`/course/`);
  return res.data;
};

export const getCourse = async (id: number) => {
  const res = await get(`/course/${id}/`);
  return res.data;
};

export const getCourseChapter = async (courseId: number, chapterId: number) => {
  const res = await get(`/course/${courseId}/chapters/${chapterId}/`);
  return res.data;
};

export const getChapterExercises = async (
  courseId: number,
  chapterId: number
) => {
  const res = await get(`/course/${courseId}/chapters/${chapterId}/exercises/`);
  return res.data;
};

export const getSaleProducts = async (): Promise<ListResponse> => {
  const res = await get(`/sale/`);
  return res.data;
};

export const getSaleProduct = async (id: number) => {
  const res = await get(`/sale/${id}/`);
  return res.data;
};

export const getMyCourses = async (): Promise<ListResponse> => {
  const res = await get(`/user-course/`);
  return res.data;
};

export const getMyCoursesByPage = async (page: string) => {
  const res = await get(`/user-course/?page=${page}`);
  return res.data;
};

export const getMyCart = async () => {
  const res = await get(`/cart/get`);
  return res.data;
};

export const answerExercise = async (exerciseId: number, answer: string) => {
  const res = await post(`/course/exercises/${exerciseId}/answers/`, {
    answer,
  });
  return res.data;
};

export const evaluateExercise = async (answer: string) => {
  try {
    const res = await axios.post(`https://cdn.luna-gang.com/evaluate`, {
      code: answer,
    });
    return res.data;
  } catch (error) {
    return { error: String(error) };
  }
};
