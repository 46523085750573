function PlayedVideo() {
  return (
    <svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M41.8337 21.0003C41.8337 32.5066 32.5066 41.8337 21.0004 41.8337C17.667 41.8337 14.517 41.0503 11.7254 39.6587C10.9879 39.2775 10.1366 39.179 9.3316 39.3816L4.6941 40.6232C4.23508 40.7457 3.75192 40.7453 3.29312 40.622C2.83431 40.4988 2.416 40.257 2.08017 39.9209C1.74435 39.5849 1.50282 39.1665 1.37984 38.7076C1.25686 38.2487 1.25675 37.7655 1.37952 37.3066L2.6191 32.6691C2.82072 31.8645 2.72144 31.0139 2.33993 30.2774C0.905835 27.3955 0.161919 24.2194 0.167018 21.0003C0.167018 9.49408 9.4941 0.166992 21.0004 0.166992C32.5066 0.166992 41.8337 9.49408 41.8337 21.0003ZM29.3337 21.0003C29.3337 19.2399 27.4024 18.0545 23.542 15.6816C19.6254 13.2753 17.667 12.0732 16.2087 12.9566C14.7503 13.8399 14.7504 16.2274 14.7504 21.0003C14.7504 25.7732 14.7503 28.1607 16.2087 29.0441C17.6691 29.9274 19.6254 28.7253 23.542 26.3191C27.4024 23.9462 29.3337 22.7587 29.3337 21.0003Z"
        fill="#6B51D1"
      />
    </svg>
  );
}

export default PlayedVideo;
