import Text from "../../../components/Text";
import {
  PlayedVideo,
  PlayingVideo,
  PlayVideo,
} from "../../../components/icons";
import { ChapterProgressType } from "../../types";

type Props = {
  name: string;
  index: number;
  onClick: (index: number) => void;
  progress?: ChapterProgressType;
  videoDuration: number;
  chapterId: number;
};

function Chapter({
  name,
  index,
  onClick,
  progress,
  videoDuration,
  chapterId,
}: Props) {
  const progressIcon = (videoProgress: number = 0) => {
    if (videoProgress >= 95) return <PlayedVideo />;
    else if (videoProgress > 0) return <PlayingVideo />;
    else return <PlayVideo />;
  };

  const secondsToTime = (seconds: number): string => {
    const result = new Date(seconds * 1000).toISOString().slice(11, 19);
    return result;
  };

  return (
    <div
      className="flex items-center gap-2 cursor-pointer bg-white px-3 py-5 w-[300px]"
      onClick={() => onClick(chapterId)}
    >
      <div className="flex justify-center w-8">
        {progressIcon(progress?.videoProgress)}
      </div>
      <div className="flex flex-col">
        <Text weight="medium">{`${index + 1}. ${name}`}</Text>
        <div className="text-sm pl-2">{secondsToTime(videoDuration)}</div>
      </div>
    </div>
  );
}

export default Chapter;
