import { twMerge } from "tailwind-merge";
import { clsx, ClassValue } from "clsx";
import dayjs from "dayjs";

export const cn = (...inputs: ClassValue[]) => {
  return twMerge(clsx(inputs));
};

export const formatDate = (date: string) => {
  return dayjs(date).format("DD/MM/YYYY");
};

export const formatPrice = (price: number) => {
  return price.toLocaleString("th-TH");
};

export const translator = (text: string) => {
  const dictionary = [["This field must be unique.", "ถูกใช้งานแล้ว"]];

  return dictionary.find((item) => item[0] === text)?.[1] || text;
};
