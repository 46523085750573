import { useEffect, useState } from "react";
import { useParams, Navigate } from "react-router-dom";
import {
  getCourse,
  getCourseChapter,
  getChapterExercises,
} from "../../../apis";
import { CourseLearningType, ChapterType, ExerciseType } from "../types";
import LogoWithName from "../components/LogoWithName";
import GoBackButton from "../components/GoBackButton";
import Chapter from "./components/Chapter";
import LearningArea from "./components/LearningArea";
import Text from "../../components/Text";
import Exercise from "./components/Exercise";
import ExerciseArea from "./components/ExerciseArea";

function Learning() {
  const { id } = useParams();

  const [course, setCourse] = useState<CourseLearningType>();
  const [exercises, setExercises] = useState<ExerciseType[]>([]);
  const [currentChapterData, setCurrentChapterData] = useState<ChapterType>();
  const [currentChapterId, setCurrentChapterId] = useState<number>(0);

  const fetchCourse = async () => {
    const res = await getCourse(parseInt(id!));
    setCourse(res);
    return res;
  };

  useEffect(() => {
    fetchCourse().then((res) => {
      handleChapterClick(
        res.progress.chapters.findLast(
          (chapter: any) => chapter.videoProgress > 0
        )?.id ?? res.chapters[0].id
      );
    });
  }, []);

  if (course?.chapters && !course?.chapters[0]?.id) {
    return <Navigate to={`/study/course/${id}`} />;
  }

  const handleChapterClick = (chapterId: number) => {
    getCourseChapter(parseInt(id!), chapterId)
      .then((res) => {
        setExercises([]);
        setCurrentChapterData(res);
        fetchCourse();
      })
      .catch((error) => {
        alert("เนื้อหานี้ยังไม่ได้รับอนุญาติให้เข้าถึง");
      });
  };

  const handleExerciseClick = (chapterId: number) => {
    getChapterExercises(parseInt(id!), chapterId)
      .then((res) => {
        setCurrentChapterData(undefined);
        setExercises(res);
        setCurrentChapterId(chapterId);
        fetchCourse();
      })
      .catch((error) => {
        alert("เนื้อหานี้ยังไม่ได้รับอนุญาติให้เข้าถึง");
      });
  };

  return (
    <div className="flex justify-center w-full bg-slate-100 min-h-screen">
      <div className="flex flex-col w-[1000px] items-center bg-white h-screen">
        <div className="w-full flex items-center justify-between px-4 shadow-lg z-10 h-[100px]">
          <GoBackButton label="ย้อนกลับ" to="/study" />
          <LogoWithName />
        </div>
        <div className="flex w-full grow z-0 overflow-hidden">
          <div className="flex flex-col gap-4 bg-[#6b51d125] py-4 px-2 overflow-y-scroll h-full min-w-[320px] max-w-[320px]">
            <Text size={20} weight="medium" className="">
              {course?.name}
            </Text>
            {course?.chapters?.map((chapter, index) => (
              <>
                <Chapter
                  key={chapter.id}
                  name={chapter.name}
                  index={index}
                  chapterId={chapter.id}
                  onClick={handleChapterClick}
                  progress={course?.progress?.chapters.find(
                    (progressChapter) => progressChapter.id === chapter.id
                  )}
                  videoDuration={chapter.video?.duration ?? 0}
                />
                {chapter.exercises && chapter.exercises.length ? (
                  <Exercise
                    chapterId={chapter.id}
                    onClick={handleExerciseClick}
                    exercises={chapter.exercises}
                  />
                ) : null}
              </>
            ))}
          </div>
          {exercises && exercises.length ? (
            <div className="overflow-y-scroll flex grow h-full">
              {exercises ? (
                <ExerciseArea
                  exercises={exercises}
                  chapterId={currentChapterId}
                  fetchExercises={(chapterId: number) =>
                    handleExerciseClick(chapterId)
                  }
                />
              ) : null}
            </div>
          ) : (
            <div className="overflow-y-scroll flex grow h-full">
              {course ? <LearningArea chapter={currentChapterData} /> : null}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Learning;
