import React from "react";
import { Link } from "react-router-dom";

function Eample() {
  return (
    <div className="w-full min-h-screen bg-slate-900 flex flex-col text-white gap-10 text-3xl py-10 px-10">
      <Link to={"1"}>
        <div>Example 1</div>
      </Link>
      <Link to={"2"}>
        <div>Example 2</div>
      </Link>
      <Link to={"4"}>
        <div>Example 4</div>
      </Link>
    </div>
  );
}

export default Eample;
