function DownloadFile() {
  return (
    <svg
      width="34"
      height="42"
      viewBox="0 0 34 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.9997 31.417L25.333 23.0837L22.4163 20.167L19.083 23.3962V14.7503H14.9163V23.3962L11.583 20.167L8.66634 23.0837L16.9997 31.417ZM4.49968 41.8337C3.35384 41.8337 2.37259 41.4253 1.55593 40.6087C0.739261 39.792 0.331622 38.8114 0.333011 37.667V12.667L12.833 0.166992H29.4997C30.6455 0.166992 31.6268 0.575326 32.4434 1.39199C33.2601 2.20866 33.6677 3.18921 33.6663 4.33366V37.667C33.6663 38.8128 33.258 39.7941 32.4413 40.6107C31.6247 41.4274 30.6441 41.8351 29.4997 41.8337H4.49968ZM4.49968 37.667H29.4997V4.33366H14.6038L4.49968 14.4378V37.667Z"
        fill="#6B51D1"
      />
    </svg>
  );
}

export default DownloadFile;
