import { Logo } from "../../components/icons";
import Text from "../../components/Text";
import { Link } from "react-router-dom";

function LogoWithName() {
  return (
    <Link to={"/study"}>
      <div className="flex items-center gap-1 select-none">
        <div className="flex w-10">
          <Logo />
        </div>
        <Text
          className="uppercase whitespace-nowrap"
          size={23}
          weight="semi-medium"
          font="font-mon-sert"
        >
          Private Session
        </Text>
      </div>
    </Link>
  );
}

export default LogoWithName;
