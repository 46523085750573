import Text from "../../components/Text";
import MyCourseCard from "./components/MyCourseCard";
import NoCourseDisplay from "./components/NoCourseDisplay";
import { MyCourseType } from "../types";
import { MyCoursePageType } from "../types";
import { useState, useEffect } from "react";
import { getMyCourses } from "../../../apis";
import { Nav } from "../components";
import { Link } from "react-router-dom";
import Pagination from "../../components/Pagination";
import { getMyCoursesByPage } from "../../../apis";
function MyCourse() {
  const [courses, setCourses] = useState<MyCourseType[]>([]);
  const [page, setPage] = useState<MyCoursePageType>();
  const handlePageChange = (page: number) => {
    getMyCoursesByPage(`${page}`).then((res) => {
      setCourses(res.results);
      setPage(res);
    });
  };
  useEffect(() => {
    getMyCourses().then((res) => {
      setCourses(res.results);
      setPage(res as any);
    });
  }, []);

  return (
    <div className="flex flex-col items-center w-full min-h-screen">
      <Nav />
      <div className="flex flex-col w-full grow items-center bg-[#6B51D1]/10">
        {/* <div className="flex flex-row w-full h-[110px] max-w-[1200px] justify-between items-center px-4">
          <Text size={34} weight="semi-medium" color="#000">
            คอร์สเรียนของฉัน
          </Text>
          <Link to="/study">
            <Text
              className="underline underline-offset-2"
              size={20}
              weight="medium"
              color="#6B51D1"
            >
              เลือกซื้อคอร์สเพิ่ม
            </Text>
          </Link>
        </div> */}
        <div className="w-full h-[3px] bg-[#CBD5E1]" />
        <div className="flex flex-col w-full grow max-w-[648px]">
          <div className="flex flex-row w-full justify-between items-center pt-4 pb-3">
            <Text size={34} weight="semi-bold" color="#6B51D1">
              คอร์สเรียนของฉัน
            </Text>
            <Text size={20} weight="medium" color="#94A3B8">
              {page ? page?.count : "0"} รายการ
            </Text>
          </div>
          <div className="w-full grow bg-white rounded-md border border-[#CBD5E1] px-6 flex flex-col items-center overflow-scroll">
            {courses?.length > 0 ? (
              courses.map((e) => <MyCourseCard {...e} />)
            ) : (
              <NoCourseDisplay />
            )}
          </div>
          <div className="w-full flex justify-center pt-8 pb-11">
            {page && (
              <Pagination count={page.count} onPageChange={handlePageChange} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default MyCourse;
