import React from "react";

function Logo() {
  return (
    <svg
      width="73"
      height="97"
      viewBox="0 0 73 97"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M57.3202 46.0282C63.7279 50.0415 63.5273 59.4426 56.9543 63.1788L15.6943 86.6319C9.03485 90.4172 0.768353 85.6074 0.768353 77.9473L0.768353 28.6525C0.768353 20.8022 9.40712 16.0195 16.0602 20.1865L57.3202 46.0282Z"
        fill="url(#paint0_linear_93_156)"
        stroke="black"
        strokeWidth="1.53684"
      />
      <path
        d="M49.8612 45.457C56.359 49.5527 56.1548 59.094 49.4877 62.908L16.5822 81.7319C9.79452 85.6148 1.34493 80.7139 1.34493 72.8942L1.34493 33.3291C1.34493 25.3147 10.1757 20.4423 16.9557 24.7159L49.8612 45.457Z"
        fill="white"
        stroke="black"
        strokeWidth="1.15263"
      />
      <path
        d="M49.8612 43.1518C56.359 47.2475 56.1548 56.7888 49.4877 60.6028L16.5822 79.4267C9.79452 83.3096 1.34493 78.4088 1.34493 70.589L1.34493 31.024C1.34493 23.0095 10.1757 18.1371 16.9557 22.4107L49.8612 43.1518Z"
        fill="white"
        stroke="black"
        strokeWidth="1.15263"
      />
      <path
        d="M57.3202 36.8073C63.7279 40.8205 63.5273 50.2216 56.9543 53.9579L15.6943 77.4109C9.03485 81.1963 0.768353 76.3865 0.768353 68.7264L0.768353 19.4316C0.768353 11.5812 9.40712 6.79856 16.0602 10.9655L57.3202 36.8073Z"
        fill="url(#paint1_linear_93_156)"
        stroke="black"
        strokeWidth="1.53684"
      />
      <defs>
        <linearGradient
          id="paint0_linear_93_156"
          x1="72.9999"
          y1="52.8288"
          x2="-6.43134e-05"
          y2="52.8288"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C33ED4" />
          <stop offset="1" stopColor="#6B51D1" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_93_156"
          x1="72.9999"
          y1="43.6079"
          x2="-6.43134e-05"
          y2="43.6079"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C33ED4" />
          <stop offset="1" stopColor="#6B51D1" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Logo;
