import { useEffect, useState } from "react";
import { useParams, Navigate } from "react-router-dom";
import { getCourse } from "../../apis";
import { CourseDetailType } from "./types";
import { ButtonGradient, Button } from "../components";
import Text from "../components/Text";

function Course() {
  const { id } = useParams();

  const [course, setCourse] = useState<CourseDetailType>();

  useEffect(() => {
    getCourse(parseInt(id!)).then((res) => setCourse(res));
  }, []);

  if (course?.chapters && course?.chapters[0]?.id) {
    return <Navigate to={"learning"} />;
  }

  console.log(
    `${process.env.REACT_APP_BACKEND}${course?.profile.file.split("api")[1]}`
  );

  return (
    <div className="flex flex-col w-full items-center gap-8">
      {course && (
        <img
          className="w-[800px] h-[400px] object-contain"
          src={`${process.env.REACT_APP_BACKEND}${
            course?.profile.file.split("api")[1]
          }`}
          alt=""
        />
      )}
      <Text size={48}>Comming Soon...</Text>
    </div>
  );
}

export default Course;
