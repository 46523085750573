import React from "react";

const ArrowLeftCurve = () => {
  return (
    <svg
      width="23"
      height="21"
      viewBox="0 0 23 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.20833 2L2 7.20833L7.20833 12.4167"
        stroke="#6B51D1"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 7.20825H10.3333C16.0865 7.20825 20.75 11.8718 20.75 17.6249V18.6666"
        stroke="#6B51D1"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowLeftCurve;
