import Background from "../components/Background";
import Card from "../components/Card";
import Text from "../components/Text";
import InputTextWithLabel from "../components/InputTextWithLabel";
import {
  Email,
  PersonFill,
  PersonName,
  Telephone,
  Key,
  ShowPassword,
  HidePassword,
  Correct,
} from "../components/icons";
import * as y from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { set, useForm } from "react-hook-form";
import { Button } from "../components";
import { Link, useNavigate } from "react-router-dom";
import { LogoWithName, GoBackButton } from "./components";
import { useState } from "react";
import { register as registerUser, me } from "../../apis";

const checkPhoneNumber =
  /^[\+]?[(]?[0]{1}[)]?[-\s\.]?[6,8,9]{1}[-\s\.]?[0-9]{8}$/gim;

const RegisterSchema = y.object({
  email: y.string().email().required(),
  password: y.string().required().min(8),
  confirmPassword: y
    .string()
    .required()
    .min(8)
    .oneOf([y.ref("password"), ""], "Passwords must match"),
  firstName: y.string().required(),
  lastName: y.string().required(),
  phoneNumber: y
    .string()
    .required()
    .matches(checkPhoneNumber, "Phone number is not valid"),
  checkbox: y.boolean().required(),
});

function Register() {
  const {
    setError,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(RegisterSchema),
  });

  const navigate = useNavigate();

  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);

  const onSubmit = async (formData: any) => {
    if (formData?.checkbox) {
      registerUser(
        formData?.phoneNumber,
        formData?.firstName,
        formData?.lastName,
        formData?.email,
        formData?.password
      )
        .then((res) => {
          navigate("/study/login");
        })
        .catch((err) => {
          if (err.response?.data) {
            for (const key in err.response.data) {
              switch (key) {
                case "email":
                  setError("email", { message: err.response.data[key][0] });
                  break;

                case "phone":
                  setError("phoneNumber", {
                    message: err.response.data[key][0],
                  });
                  break;
              }
            }
          }
        });
    } else {
      console.log("No");
    }
  };

  return (
    <>
      <div className="absolute top-0 w-full min-h-screen overflow-hidden -z-50">
        <Background />
      </div>
      <form className="flex flex-col w-full  justify-start items-center gap-7 p-8">
        <LogoWithName />
        <Card className="w-[528px] p-[38px] flex flex-col items-center ">
          <div className="flex items-center gap-1">
            <div className="scale-90">
              <PersonFill />
            </div>
            <Text weight="bold" size={20}>
              ลงทะเบียน
            </Text>
          </div>
          <InputTextWithLabel
            label="อีเมล"
            placeholder="อีเมล"
            icon={<Email />}
            require
            form={register("email")}
            error={errors?.email?.message}
            width={"100%"}
          />
          <div className="flex w-full gap-4 items-end">
            <InputTextWithLabel
              label="ชื่อ"
              placeholder="ชื่อ"
              icon={<PersonName />}
              require
              form={register("firstName")}
              error={errors?.firstName?.message}
              width={"100%"}
            />
            <InputTextWithLabel
              placeholder="นามสกุล"
              require
              form={register("lastName")}
              error={errors?.lastName?.message}
              width={"100%"}
            />
          </div>
          <InputTextWithLabel
            label="เบอร์โทรศัพท์"
            placeholder="เบอร์โทรศัพท์"
            icon={<Telephone />}
            require
            form={register("phoneNumber")}
            error={errors?.phoneNumber?.message}
            width={"100%"}
          />
          <div className="flex w-full relative">
            <InputTextWithLabel
              label="สร้างรหัสผ่าน"
              placeholder="รหัสผ่าน"
              icon={<Key />}
              require
              form={register("password")}
              error={errors?.password?.message}
              width={"100%"}
              type={isShowPassword ? "text" : "password"}
            />
            <div
              className="absolute top-[calc(50%-12px)] right-3"
              onClick={() => setIsShowPassword((prev) => !prev)}
            >
              {isShowPassword ? (
                <div>
                  <ShowPassword />
                </div>
              ) : (
                <div>
                  <HidePassword />
                </div>
              )}
            </div>
          </div>
          <div className="flex w-full relative">
            <InputTextWithLabel
              label="ยืนยันรหัสผ่าน"
              placeholder="ยืนยันรหัสผ่าน"
              icon={<Key />}
              require
              form={register("confirmPassword")}
              error={errors?.confirmPassword?.message}
              width={"100%"}
              type={isShowConfirmPassword ? "text" : "password"}
            />
            <div
              className="absolute top-[calc(50%-12px)] right-3"
              onClick={() => setIsShowConfirmPassword((prev) => !prev)}
            >
              {isShowConfirmPassword ? (
                <div>
                  <ShowPassword />
                </div>
              ) : (
                <div>
                  <HidePassword />
                </div>
              )}
            </div>
          </div>
          <div className="flex w-full justify-start gap-2">
            <InputTextWithLabel
              label=""
              placeholder=""
              require
              form={register("checkbox")}
              error={errors?.checkbox?.message}
              width={26}
              height={26}
              type="checkbox"
            />
            <div className="w-[340px] -translate-y-1">
              <Text size={12} color="black" weight="medium">
                การสมัครใช้งาน เราถือว่าคุณยอมรับ
                <Link to={"/study"} className="text-[#6B51D1]">
                  ข้อกําหนดและเงื่อนไขในการใช้งาน
                </Link>
                ของ Private Session แล้ว
              </Text>
            </div>
          </div>

          <Button className="w-full" onClick={handleSubmit(onSubmit)}>
            ลงทะเบียน
          </Button>
          <div className="flex gap-3 mt-5">
            <Text size={14} weight="medium">
              มีบัญชีแล้ว ?
            </Text>
            <Link to="/study/login">
              <Text size={14} color="#6B51D1" weight="medium">
                คลิกเพื่อเข้าสู่ระบบ
              </Text>
            </Link>
          </div>
        </Card>
        <GoBackButton label="กลับหน้าหลัก" to="/study" />
      </form>
    </>
  );
}

export default Register;
