import Text from "./Text";
import ArrowRegular from "./icons/ArrowRegular";
import { useState, useEffect } from "react";

type Props = {
  count: number;
  onPageChange: (page: number) => void;
};

function Pagination({ count, onPageChange }: Props) {
  const page = Math.ceil(count / 10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const pageArray: number[] = [];
  const NextPage = () => {
    setCurrentPage(currentPage + 1);
  };
  const PreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };
  for (let i = 0; i < page; i++) {
    pageArray.push(i + 1);
  }
  useEffect(() => {}, [currentPage]);
  return (
    <div className="flex flex-row">
      <button
        disabled={currentPage === 1}
        onClick={() => {
          onPageChange(currentPage - 1);
          PreviousPage();
        }}
        className="w-10 h-10 rounded-l-md bg-white border border-grape flex justify-center items-center hover:bg-[#6b51d11a] cursor-pointer"
      >
        <ArrowRegular />
      </button>
      {pageArray.map((pageNumber) => (
        <div
          onClick={() => {
            setCurrentPage(pageNumber);
            onPageChange(pageNumber);
          }}
          className={`${
            currentPage === pageNumber
              ? "bg-grape border-grape"
              : "bg-white border-grape hover:bg-[#6b51d11a]"
          } w-10 h-10 border-y border-r flex justify-center items-center  cursor-pointer`}
        >
          <Text
            size={20}
            weight="medium"
            color={`${currentPage === pageNumber ? "#FFFFFF" : "#6B51D1"}`}
          >
            {pageNumber}
          </Text>
        </div>
      ))}
      <button
        disabled={currentPage === page}
        onClick={() => {
          onPageChange(currentPage + 1);
          NextPage();
        }}
        className="w-10 h-10 rounded-r-md bg-white border-y border-r border-grape flex justify-center items-center hover:bg-[#6b51d11a] cursor-pointer"
      >
        <div className="rotate-180">
          <ArrowRegular />
        </div>
      </button>
    </div>
  );
}
export default Pagination;
