import React from 'react'

function PersonName() {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 8C8.99456 8 9.94839 7.60491 10.6517 6.90165C11.3549 6.19839 11.75 5.24456 11.75 4.25C11.75 3.25544 11.3549 2.30161 10.6517 1.59835C9.94839 0.895088 8.99456 0.5 8 0.5C7.00544 0.5 6.05161 0.895088 5.34835 1.59835C4.64509 2.30161 4.25 3.25544 4.25 4.25C4.25 5.24456 4.64509 6.19839 5.34835 6.90165C6.05161 7.60491 7.00544 8 8 8ZM10.5 4.25C10.5 4.91304 10.2366 5.54893 9.76777 6.01777C9.29893 6.48661 8.66304 6.75 8 6.75C7.33696 6.75 6.70107 6.48661 6.23223 6.01777C5.76339 5.54893 5.5 4.91304 5.5 4.25C5.5 3.58696 5.76339 2.95107 6.23223 2.48223C6.70107 2.01339 7.33696 1.75 8 1.75C8.66304 1.75 9.29893 2.01339 9.76777 2.48223C10.2366 2.95107 10.5 3.58696 10.5 4.25ZM15.5 14.25C15.5 15.5 14.25 15.5 14.25 15.5H1.75C1.75 15.5 0.5 15.5 0.5 14.25C0.5 13 1.75 9.25 8 9.25C14.25 9.25 15.5 13 15.5 14.25ZM14.25 14.245C14.2487 13.9375 14.0575 13.0125 13.21 12.165C12.395 11.35 10.8612 10.5 8 10.5C5.1375 10.5 3.605 11.35 2.79 12.165C1.9425 13.0125 1.7525 13.9375 1.75 14.245H14.25Z" fill="black" />
        </svg>
    )
}

export default PersonName