import React from "react";

function PlayingVideo() {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_63_1028"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="46"
        height="46"
      >
        <path
          d="M23.0003 43.8337C34.5066 43.8337 43.8337 34.5066 43.8337 23.0003C43.8337 11.4941 34.5066 2.16699 23.0003 2.16699C11.4941 2.16699 2.16699 11.4941 2.16699 23.0003C2.16699 34.5066 11.4941 43.8337 23.0003 43.8337Z"
          fill="white"
          stroke="white"
          strokeWidth="4"
          strokeLinejoin="round"
        />
        <path
          d="M17.792 16.75V29.25M28.2087 16.75V29.25"
          stroke="black"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </mask>
      <g mask="url(#mask0_63_1028)">
        <path d="M-2 -2H48V48H-2V-2Z" fill="#6B51D1" />
      </g>
    </svg>
  );
}

export default PlayingVideo;
