import SaleProductCard from "./components/SaleProductCard";
import { SaleProductType } from "../types";
import Text from "../../components/Text";
import { ButtonGradient } from "../../components";

type Props = {
  title: string;
  saleProducts: SaleProductType[];
};

function SaleProducts({ title, saleProducts }: Props) {
  return (
    <div className="w-full py-[47px] flex flex-col gap-[25px]">
      <div className="w-full flex justify-between">
        <Text size={48} weight="semi-medium">
          {title}
        </Text>
        <ButtonGradient className="h-[53px] mt-9 rounded-[39px]">
          <Text size={20} weight="semi-medium" color="#fff">
            ดูเพิ่มเติม {">"}
          </Text>
        </ButtonGradient>
      </div>
      <div className="grid grid-cols-4 gap-[46px]">
        {saleProducts.map((saleProduct) => (
          <SaleProductCard {...saleProduct} />
        ))}
      </div>
    </div>
  );
}

export default SaleProducts;
