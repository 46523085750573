function BookHeart() {
  return (
    <svg
      width="26"
      height="25"
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.25 8.3335V19.7918C3.25 22.0845 5.11875 22.9168 6.375 22.9168H22V20.8335H6.3875C5.90625 20.821 5.33333 20.6314 5.33333 19.7918C5.33333 18.9522 5.90625 18.7627 6.3875 18.7502H22V4.16683C22 3.01787 21.0656 2.0835 19.9167 2.0835H6.375C5.11875 2.0835 3.25 2.91579 3.25 5.2085V8.3335ZM6.375 4.16683H19.9167V16.6668H5.33333V5.2085C5.33333 4.36891 5.90625 4.17933 6.375 4.16683Z"
        fill="url(#paint0_linear_238_6315)"
      />
      <path
        d="M12.6219 14.5835L16.1114 11.1575C16.3266 10.9506 16.4978 10.7023 16.6147 10.4277C16.7316 10.153 16.7919 9.85757 16.7919 9.55906C16.7919 9.26055 16.7316 8.96511 16.6147 8.69044C16.4978 8.41578 16.3266 8.16755 16.1114 7.96062C15.6777 7.5325 15.0928 7.29245 14.4833 7.29245C13.8739 7.29245 13.2889 7.5325 12.8552 7.96062L12.6219 8.18771L12.3885 7.95958C11.9549 7.53152 11.3702 7.2915 10.7609 7.2915C10.1516 7.2915 9.56688 7.53152 9.13331 7.95958C8.91815 8.1665 8.74699 8.41474 8.63007 8.6894C8.51315 8.96406 8.45288 9.2595 8.45288 9.55802C8.45288 9.85653 8.51315 10.152 8.63007 10.4266C8.74699 10.7013 8.91815 10.9495 9.13331 11.1565L12.6219 14.5835Z"
        fill="url(#paint1_linear_238_6315)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_238_6315"
          x1="12.625"
          y1="2.0835"
          x2="12.625"
          y2="22.9168"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6B51D1" />
          <stop offset="1" stopColor="#C33ED4" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_238_6315"
          x1="12.6224"
          y1="7.2915"
          x2="12.6224"
          y2="14.5835"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6B51D1" />
          <stop offset="1" stopColor="#C33ED4" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default BookHeart;
