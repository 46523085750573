import React from "react";

const ArrowRegular = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="21"
      viewBox="0 0 12 21"
      fill="none"
    >
      <path
        d="M2.73802 10.4998L11.7344 1.4092C11.8085 1.33594 11.867 1.24896 11.9067 1.15331C11.9464 1.05765 11.9663 0.955205 11.9655 0.851908C11.9646 0.748611 11.9429 0.64651 11.9016 0.551513C11.8604 0.456516 11.8003 0.37051 11.725 0.298471C11.6497 0.226431 11.5607 0.16979 11.463 0.131825C11.3653 0.0938595 11.2609 0.0753236 11.1558 0.07729C11.0508 0.0792564 10.9472 0.101686 10.851 0.143281C10.7548 0.184875 10.668 0.244809 10.5956 0.319614L1.061 9.95503C0.916682 10.1009 0.835938 10.2963 0.835938 10.4998C0.835938 10.7033 0.916682 10.8988 1.061 11.0446L10.5956 20.68C10.668 20.7548 10.7548 20.8148 10.851 20.8564C10.9472 20.898 11.0508 20.9204 11.1558 20.9224C11.2609 20.9243 11.3653 20.9058 11.463 20.8678C11.5607 20.8299 11.6497 20.7732 11.725 20.7012C11.8003 20.6291 11.8604 20.5431 11.9016 20.4481C11.9429 20.3531 11.9646 20.251 11.9655 20.1477C11.9663 20.0444 11.9464 19.942 11.9067 19.8463C11.867 19.7507 11.8085 19.6637 11.7344 19.5904L2.73802 10.4998Z"
        fill="#6B51D1"
      />
    </svg>
  );
};

export default ArrowRegular;
