import { Outlet } from "react-router-dom";
import Nav from "./components/Nav";
import Footer from "./components/Footer";

function Study() {
  // const dispatch = useDispatch();
  // const user = useSelector((state: any) => state.user);

  // if (!user.id) {
  //   return <Navigate to={"/study/login"} />;
  // }

  return (
    <div className="flex flex-col items-center w-full">
      <Nav />
      <Outlet />
      <Footer />
    </div>
  );
}

export default Study;
