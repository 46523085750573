import { Link } from "react-router-dom";

function App() {
  const css = {
    service:
      "w-[400px] h-[400px] flex justify-center items-center text-7xl border shadow-lg hover:scale-110 duration-500 hover:shadow-2xl",
  };

  return (
    <div className="w-full min-h-screen flex justify-evenly items-center">
      <Link to="study">
        <div className={css.service}>Study</div>
      </Link>
      <Link to="services">
        <div className={css.service}>Services</div>
      </Link>
    </div>
  );
}

export default App;
