import { useEffect, useState } from "react";
import { getSaleProducts } from "../../apis";
import { SaleProductType } from "./types";
import SaleProducts from "./SaleProducts";
import Slider from "../services/components/Slider";
import slide1 from "../../images/ps_slide1.png";

function HomePage() {
  const [saleProducts, setSaleProducts] = useState<SaleProductType[]>([]);

  const fetchSaleProducts = async () => {
    const response = await getSaleProducts();
    const saleProducts = response.results;

    setSaleProducts(saleProducts);
  };

  useEffect(() => {
    fetchSaleProducts();
  }, []);

  let slides = [
    {
      url: slide1,
    },
    {
      url: "https://images.unsplash.com/photo-1506744038136-46273834b3fb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
    },
    {
      url: "https://images.unsplash.com/photo-1434725039720-aaad6dd32dfe?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1642&q=80",
    },
  ];

  return (
    <main className="w-full h-full flex flex-col items-center">
      <section className="w-full h-[671px] bg-[#6B51D1]/10">
        <Slider slides={slides} width={1000} height={600} />
      </section>
      <section className="flex w-full max-w-[1200px] flex-col gap-[78px]">
        <SaleProducts title="คอร์สทั้งหมด" saleProducts={saleProducts} />
      </section>
    </main>
  );
}

export default HomePage;
