import React, { PropsWithChildren } from "react";
import { cn } from "../../utils";

type Props = {
  weight?:
    | "thin"
    | "light"
    | "semi-medium"
    | "medium"
    | "semi-bold"
    | "bold"
    | "black";
  size?: number;
  props?: React.ButtonHTMLAttributes<HTMLButtonElement>;
  color?: string;
  className?: string;
  font?: "font-noto-th" | "font-mon-sert";
};

function Text({
  size = 16,
  weight = "medium",
  color = "#000000",
  children,
  className,
  font = "font-noto-th",
}: PropsWithChildren<Props>) {
  return (
    <div
      style={{ fontSize: size, color: color }}
      className={cn(
        `${font}`,
        {
          "font-thin": weight === "thin",
          "font-light": weight === "light",
          "font-[400]": weight === "semi-medium",
          "font-medium": weight === "medium",
          "font-[600]": weight === "semi-bold",
          "font-bold": weight === "bold",
          "font-black": weight === "black",
        },
        className
      )}
    >
      {children}
    </div>
  );
}

export default Text;
