import React from 'react'

const Location = () => {
    return (
        <svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.00033 4.41675C6.55286 4.41675 7.08276 4.63624 7.47346 5.02694C7.86417 5.41764 8.08366 5.94755 8.08366 6.50008C8.08366 6.77367 8.02977 7.04458 7.92508 7.29734C7.82038 7.5501 7.66692 7.77977 7.47346 7.97322C7.28001 8.16668 7.05034 8.32013 6.79758 8.42483C6.54482 8.52953 6.27391 8.58341 6.00033 8.58341C5.44779 8.58341 4.91789 8.36392 4.52719 7.97322C4.13649 7.58252 3.91699 7.05262 3.91699 6.50008C3.91699 5.94755 4.13649 5.41764 4.52719 5.02694C4.91789 4.63624 5.44779 4.41675 6.00033 4.41675ZM6.00033 0.666748C7.54742 0.666748 9.03115 1.28133 10.1251 2.37529C11.2191 3.46925 11.8337 4.95299 11.8337 6.50008C11.8337 10.8751 6.00033 17.3334 6.00033 17.3334C6.00033 17.3334 0.166992 10.8751 0.166992 6.50008C0.166992 4.95299 0.781574 3.46925 1.87554 2.37529C2.9695 1.28133 4.45323 0.666748 6.00033 0.666748ZM6.00033 2.33341C4.89526 2.33341 3.83545 2.7724 3.05405 3.5538C2.27265 4.3352 1.83366 5.39501 1.83366 6.50008C1.83366 7.33341 1.83366 9.00008 6.00033 14.5917C10.167 9.00008 10.167 7.33341 10.167 6.50008C10.167 5.39501 9.72801 4.3352 8.9466 3.5538C8.1652 2.7724 7.10539 2.33341 6.00033 2.33341Z" fill="black" />
        </svg>

    )
}

export default Location