function History() {
  return (
    <svg
      width="26"
      height="25"
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.625 21.875C10.2292 21.875 8.14132 21.0806 6.36146 19.4917C4.5816 17.9028 3.56181 15.9194 3.30208 13.5417H5.4375C5.68056 15.3472 6.48368 16.8403 7.84687 18.0208C9.21007 19.2014 10.8028 19.7917 12.625 19.7917C14.6562 19.7917 16.3795 19.084 17.7948 17.6687C19.2101 16.2535 19.9174 14.5306 19.9167 12.5C19.9167 10.4687 19.209 8.74549 17.7937 7.33021C16.3785 5.91493 14.6556 5.20764 12.625 5.20833C11.4271 5.20833 10.3073 5.48611 9.26562 6.04167C8.22396 6.59722 7.34722 7.36111 6.63542 8.33333H9.5V10.4167H3.25V4.16667H5.33333V6.61458C6.21875 5.50347 7.29965 4.6441 8.57604 4.03646C9.85243 3.42882 11.2021 3.125 12.625 3.125C13.9271 3.125 15.1469 3.37257 16.2844 3.86771C17.4219 4.36285 18.4115 5.0309 19.2531 5.87188C20.0948 6.71424 20.7632 7.70382 21.2583 8.84063C21.7535 9.97743 22.0007 11.1972 22 12.5C22 13.8021 21.7524 15.0219 21.2573 16.1594C20.7622 17.2969 20.0941 18.2865 19.2531 19.1281C18.4108 19.9698 17.4212 20.6382 16.2844 21.1333C15.1476 21.6285 13.9278 21.8757 12.625 21.875ZM15.5417 16.875L11.5833 12.9167V7.29167H13.6667V12.0833L17 15.4167L15.5417 16.875Z"
        fill="url(#paint0_linear_238_6313)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_238_6313"
          x1="12.625"
          y1="3.125"
          x2="12.625"
          y2="21.875"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6B51D1" />
          <stop offset="1" stopColor="#C33ED4" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default History;
