import React from "react";
import { cn } from "../../utils";
import Text from "./Text";

type Props = {
  className?: string;
  text: string;
};

const ButtonGradientOutlined = ({ className, text }: Props) => {
  return (
    <button
      className={cn(
        "bg-gradient-to-b from-[#6B51D1] to-[#C33ED4] text-white rounded-xl bg-clip-padding p-[1px] pb-[2px] leading-normal active:opacity-90 cursor-pointer hover:opacity-95 select-none",
        className
      )}
    >
      <div className=" rounded-xl bg-white px-3 py-1">
        <Text
          weight="medium"
          size={16}
          color="transparent"
          className="bg-clip-text bg-gradient-to-b from-[#6B51D1] to-[#C33ED4]"
        >
          {text}
        </Text>
      </div>
    </button>
  );
};

export default ButtonGradientOutlined;
