import "vidstack/styles/defaults.css";
import "vidstack/styles/community-skin/video.css";

import {
  MediaCommunitySkin,
  MediaOutlet,
  MediaPlayer,
  MediaPoster,
  MediaMenu,
  MediaMenuButton,
  MediaMenuItems,
  MediaPlaybackRateMenuButton,
  MediaPlaybackRateMenuItems,
  MediaQualityMenuButton,
  MediaQualityMenuItems,
} from "@vidstack/react";
import HLS from "hls.js";
import { isHLSProvider, type MediaProviderChangeEvent } from "vidstack";

type Props = {
  url: string;
  title: string;
};

function Video({ url, title }: Props) {
  // function onPlaying(event: MediaPlayingEvent) {
  //   console.log(event);
  // }

  function onProviderChange(event: MediaProviderChangeEvent) {
    const provider = event.detail;

    if (isHLSProvider(provider)) {
      provider.library = HLS;
      provider.library = () => import("hls.js");

      const hls = new HLS({
        debug: true,
      });
      hls.loadSource(url);
      hls.attachMedia(provider.video);
    }
  }

  return (
    <>
      {url && (
        <MediaPlayer
          title={title}
          src={url}
          // poster="https://image.mux.com/VZtzUzGRv02OhRnZCxcNg49OilvolTqdnFLEqBsTwaxU/thumbnail.webp?time=268&width=980"
          // thumbnails="https://media-files.vidstack.io/sprite-fight/thumbnails.vtt"
          aspectRatio={16 / 9}
          // onPlaying={onPlaying}
          // onQualityChange={(e) => console.log(e)}
          onProviderChange={onProviderChange}
        >
          <MediaOutlet>
            <MediaPoster alt={title} />
            {/* <track
              src="https://media-files.vidstack.io/sprite-fight/subs/english.vtt"
              label="English"
              srcLang="en-US"
              kind="subtitles"
              default
            />
            <track
              src="https://media-files.vidstack.io/sprite-fight/chapters.vtt"
              srcLang="en-US"
              kind="chapters"
              default
            /> */}
          </MediaOutlet>
          <MediaCommunitySkin />
          <MediaMenu>
            <MediaMenuButton aria-label="Settings">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            </MediaMenuButton>
            <MediaMenuItems>
              {/* <MediaMenu>
                <MediaQualityMenuButton label="Quality"></MediaQualityMenuButton>
                <MediaQualityMenuItems autoLabel="Auto" />
              </MediaMenu> */}
              <MediaMenu>
                <MediaPlaybackRateMenuButton label="Speed"></MediaPlaybackRateMenuButton>
                <MediaPlaybackRateMenuItems
                  rates={[0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2]}
                  normalLabel="Normal"
                />
              </MediaMenu>
            </MediaMenuItems>
          </MediaMenu>
        </MediaPlayer>
      )}
    </>
  );
}

export default Video;
