function Logout() {
  return (
    <svg
      width="26"
      height="25"
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.25 21.875V3.125H12.625V5.20833H5.33333V19.7917H12.625V21.875H3.25ZM16.7917 17.7083L15.3594 16.1979L18.0156 13.5417H9.5V11.4583H18.0156L15.3594 8.80208L16.7917 7.29167L22 12.5L16.7917 17.7083Z"
        fill="url(#paint0_linear_238_6318)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_238_6318"
          x1="12.625"
          y1="3.125"
          x2="12.625"
          y2="21.875"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F46E5" />
          <stop offset="1" stopColor="#C33ED4" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Logout;
