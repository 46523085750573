import React from 'react'
import Text from '../../components/Text'
import { Link } from 'react-router-dom'
import { CardOrder } from './component'
import InputTextWithLabel from '../../components/InputTextWithLabel'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as y from 'yup'
import { Button } from '../../components'

const LoginSchema = y.object({
  checkbox: y.boolean(),
})

const CartOrder = () => {
  let arr = [{}, {}, {}, {}, {}]

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(LoginSchema),
  })
  return (
    <div className="w-full min-h-[calc(1095px-105px)]  h-full bg-[#6B51D11A] flex flex-col no-scrollbar">
      {/* Second nav */}
      <div className="w-full flex justify-center border-b-[3px] border-[#CBD5E1] ">
        <div className=" max-w-[1295px] w-full min-w-0 flex justify-between h-[100px] items-center">
          <Text size={34}>ตะกร้าสินค้าของคุณ</Text>
          <Link
            to={'/'}
            className=" underline underline-offset-4 decoration-[#6B51D1]"
          >
            <Text size={20} weight="medium" color="#6B51D1">
              เลือกซื้อต่อ
            </Text>
          </Link>
        </div>
      </div>

      <div className="flex justify-center w-full h-full ">
        <div className="max-w-[1295px] w-full min-w-0 flex h-full">
          {/* left */}
          <div className="flex flex-col w-[50%] max-h-[622px] h-full  justify-start">
            <div className="flex w-full justify-between items-center py-[30px]">
              <div className="flex w-[506px] justify-between items-end">
                <Text size={28} weight="semi-bold" color="#6B51D1">
                  ตะกร้าสินค้าของคุณ
                </Text>
                <Text size={20} weight="medium" color="#94A3B8">
                  เลือกซื้อต่อ
                </Text>
              </div>
            </div>
            <div className="w-[506px] h-full bg-white rounded-[15px] border border-[#CBD5E1] px-[14px] overflow-y-scroll no-scrollbar">
              {arr?.map((x, index) => (
                <CardOrder key={index} />
              ))}
            </div>
          </div>
          {/* right */}
          <div className="flex flex-col w-[50%]  h-full items-end">
            <div className="w-[450px] flex flex-col ">
              <div className="flex w-full justify-start items-center py-[30px]">
                <Text size={28} weight="semi-bold" color="#6B51D1">
                  สรุปยอดการสั่งซื้อ
                </Text>
              </div>
              <div className="w-[450px] h-[284px] flex flex-col py-[42px] px-[25px] bg-white rounded-[15px] justify-between">
                <div className="flex items-center ">
                  <Text size={24} weight="semi-bold">
                    ยอดรวม : &nbsp;
                  </Text>
                  <Text color="#6B51D1" size={32}>
                    ฿3,000.00
                  </Text>
                </div>
                <Text color="#94A3B8" size={16}>
                  คุณมี 2 รายการ ในรายการสั่งซื้อ
                </Text>
                <div className="flex w-full gap-2 items-start">
                  <InputTextWithLabel
                    type="checkbox"
                    form={register('checkbox')}
                    width={26}
                    height={26}
                  />
                  <div className="mt-1.5">
                    <Text size={14} weight="medium">
                      ใบกำกับภาษีเต็มรูปแบบ
                    </Text>
                  </div>
                </div>
                <div className="w-full flex justify-center">
                  <Button>ดำเนิการชำระเงิน</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  )
}

export default CartOrder
