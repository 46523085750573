function GoBack() {
  return (
    <svg
      width="31"
      height="28"
      viewBox="0 0 31 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.66667 2.29199L2.375 9.58366L9.66667 16.8753"
        stroke="#6B51D1"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.375 9.58398H14.0417C22.096 9.58398 28.625 16.1129 28.625 24.1673V25.6257"
        stroke="#6B51D1"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default GoBack;
