import Video from "../../components/Video";
import { ChapterType } from "../../types";
import { DownloadFile } from "../../../components/icons";
import Text from "../../../components/Text";

type Props = {
  chapter?: ChapterType;
};

function LearningArea({ chapter }: Props) {
  if (!chapter) {
    return null;
  }

  const formattedDescription = chapter.description.replace(/\r\n/g, "<br>");

  return (
    <div className="flex flex-col gap-4 grow">
      {chapter.video?.file && (
        <Video
          url={`${process.env.REACT_APP_CDN}${chapter.video.file}`}
          title={chapter.name}
        />
      )}
      <div className="flex justify-end">
        <div className="flex items-center gap-2">
          <div className="flex w-6">
            <DownloadFile />
          </div>
          <Text weight="medium" className="mr-4">
            เอกสารประกอบการเรียน
          </Text>
        </div>
      </div>
      <Text
        className="flex flex-col px-4 py-3 bg-[#6b51d125] mx-4 rounded-md"
        weight="medium"
      >
        <Text weight="medium" size={18}>
          {chapter.name}
        </Text>
        <Text size={14}>
          <span
            dangerouslySetInnerHTML={{ __html: formattedDescription }}
          ></span>
        </Text>
      </Text>
    </div>
  );
}

export default LearningArea;
