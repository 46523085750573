import React from "react";

const HidePassword = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_289_43)">
        <path
          d="M15 8.125C17.2984 8.11736 19.5523 8.75848 21.5027 9.97466C23.453 11.1908 25.0205 12.9327 26.025 15C23.9625 19.2125 19.75 21.875 15 21.875C10.25 21.875 6.0375 19.2125 3.975 15C4.97952 12.9327 6.54704 11.1908 8.49735 9.97466C10.4477 8.75848 12.7016 8.11736 15 8.125ZM15 5.625C8.75 5.625 3.4125 9.5125 1.25 15C3.4125 20.4875 8.75 24.375 15 24.375C21.25 24.375 26.5875 20.4875 28.75 15C26.5875 9.5125 21.25 5.625 15 5.625ZM15 11.875C15.8288 11.875 16.6237 12.2042 17.2097 12.7903C17.7958 13.3763 18.125 14.1712 18.125 15C18.125 15.8288 17.7958 16.6237 17.2097 17.2097C16.6237 17.7958 15.8288 18.125 15 18.125C14.1712 18.125 13.3763 17.7958 12.7903 17.2097C12.2042 16.6237 11.875 15.8288 11.875 15C11.875 14.1712 12.2042 13.3763 12.7903 12.7903C13.3763 12.2042 14.1712 11.875 15 11.875ZM15 9.375C11.9 9.375 9.375 11.9 9.375 15C9.375 18.1 11.9 20.625 15 20.625C18.1 20.625 20.625 18.1 20.625 15C20.625 11.9 18.1 9.375 15 9.375Z"
          fill="#6B51D1"
        />
        <path
          d="M1.49972 29.0002L29.5 0.500001"
          stroke="#6B51D1"
          strokeWidth="2"
        />
      </g>
      <defs>
        <clipPath id="clip0_289_43">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HidePassword;
